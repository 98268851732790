<template>
  <div class="context-menu">
    <!-- Add To Canned Responses -->
    <woot-modal
      v-if="isCannedResponseModalOpen && enabledOptions['cannedResponse']"
      :show.sync="isCannedResponseModalOpen"
      :on-close="hideCannedResponseModal"
    >
      <add-canned-modal
        :response-content="plainTextContent"
        :on-close="hideCannedResponseModal"
      />
    </woot-modal>
    <!-- Translate Content -->
    <translate-modal
      v-if="showTranslateModal"
      :content="messageContent"
      :content-attributes="contentAttributes"
      @close="onCloseTranslateModal"
    />

    <!-- Edit Content -->
    <edit-modal
      v-if="showEditModal"
      :message="removeSignatureContent"
      :modified-content="modifiedContent"
      :current-user="currentUser"
      @close="onCloseEditModal"
      @save-edit="handleSaveEdit"
    />

    <!-- Confirm Deletion -->
    <woot-delete-modal
      v-if="showDeleteModal"
      class="context-menu--delete-modal"
      :show.sync="showDeleteModal"
      :on-close="closeDeleteModal"
      :on-confirm="confirmDeletion"
      :title="$t('CONVERSATION.CONTEXT_MENU.DELETE_CONFIRMATION.TITLE')"
      :message="$t('CONVERSATION.CONTEXT_MENU.DELETE_CONFIRMATION.MESSAGE')"
      :confirm-text="$t('CONVERSATION.CONTEXT_MENU.DELETE_CONFIRMATION.DELETE')"
      :reject-text="$t('CONVERSATION.CONTEXT_MENU.DELETE_CONFIRMATION.CANCEL')"
    />
    <woot-button
      icon="more-vertical"
      color-scheme="secondary"
      variant="clear"
      size="small"
      @click="handleOpen"
    />
    <woot-context-menu
      v-if="isOpen && !isCannedResponseModalOpen"
      :x="contextMenuPosition.x"
      :y="contextMenuPosition.y"
      @close="handleClose"
    >
      <div class="menu-container">

        <menu-item
          v-if="isAudioFile"
          :option="{
            icon: 'chat',
            label: this.$t('CONVERSATION.CONTEXT_MENU.TRANSCRIPTION'),
          }"
          variant="icon"
          :isPending="isPendingTranscription"
          @click="getTranscription"
        />
        <menu-item
          v-if="showEdited"
          :option="{
            icon: 'edit',
            label: this.$t('CONVERSATION.CONTEXT_MENU.EDIT'),
          }"
          variant="icon"
          @click="handleEdit"
        />
        <menu-item
          v-if="showReply"
          :option="{
            icon: 'arrow-reply',
            label: this.$t('CONVERSATION.CONTEXT_MENU.REPLY'),
          }"
          variant="icon"
          @click="replyMessage"
        />
        <menu-item
          v-if="enabledOptions['copy']"
          :option="{
            icon: 'clipboard',
            label: this.$t('CONVERSATION.CONTEXT_MENU.COPY'),
          }"
          variant="icon"
          @click="handleCopy"
        />
        <menu-item
          v-if="enabledOptions['copy']"
          :option="{
            icon: 'translate',
            label: this.$t('CONVERSATION.CONTEXT_MENU.TRANSLATE'),
          }"
          variant="icon"
          @click="handleTranslate"
        />
        <hr />
        <menu-item
          :option="{
            icon: 'link',
            label: this.$t('CONVERSATION.CONTEXT_MENU.COPY_PERMALINK'),
          }"
          variant="icon"
          @click="copyLinkToMessage"
        />
        <menu-item
          v-if="enabledOptions['cannedResponse']"
          :option="{
            icon: 'comment-add',
            label: this.$t(
              'CONVERSATION.CONTEXT_MENU.CREATE_A_CANNED_RESPONSE'
            ),
          }"
          variant="icon"
          @click="showCannedResponseModal"
        />
        <hr
          v-if="enabledOptions['delete'] && !this.isAgent"
        />
        <menu-item
          v-if="enabledOptions['delete'] && !this.isAgent"
          :option="{
            icon: 'delete',
            label: this.$t('CONVERSATION.CONTEXT_MENU.DELETE'),
          }"
          variant="icon"
          @click="openDeleteModal"
        />

        <menu-item
          v-if="mediaUrl && !shouldShowDownloadLink"
          :option="{
            icon: 'arrow-download',
            label: this.$t('CONVERSATION.CONTEXT_MENU.DOWNLOAD') || 'Download',
          }"
          variant="icon"
          @click="handleDownload"
        />
        <menu-item
          v-if="shouldShowDownloadLink"
          :option="{
            icon: 'arrow-download',
            label: this.$t('CONVERSATION.CONTEXT_MENU.DOWNLOAD') || 'Download',
          }"
          variant="icon"
          @click="openDownloadLink"
        />
      </div>
    </woot-context-menu>
  </div>
</template>
<script>
import alertMixin from 'shared/mixins/alertMixin';
import { mapGetters } from 'vuex';
import { mixin as clickaway } from 'vue-clickaway';
import messageFormatterMixin from 'shared/mixins/messageFormatterMixin';
import AddCannedModal from 'dashboard/routes/dashboard/settings/canned/AddCanned';
import { copyTextToClipboard } from 'shared/helpers/clipboard';
import { conversationUrl, frontendURL } from '../../../helper/URLHelper';
import { ACCOUNT_EVENTS } from '../../../helper/AnalyticsHelper/events';
import TranslateModal from 'dashboard/components/widgets/conversation/bubble/TranslateModal';
import EditModal from 'dashboard/components/widgets/conversation/bubble/EditModal';
import MenuItem from '../../../components/widgets/conversation/contextMenu/menuItem.vue';

export default {
  components: {
    AddCannedModal,
    TranslateModal,
    EditModal,
    MenuItem,
  },
  mixins: [alertMixin, clickaway, messageFormatterMixin],
  props: {
    message: {
      type: Object,
      required: true,
    },
    inbox: {
      type: Object,
    },
    isOpen: {
      type: Boolean,
      default: false,
    },
    enabledOptions: {
      type: Object,
      default: () => ({}),
    },
    contextMenuPosition: {
      type: Object,
      default: () => ({}),
    },
    isEditedText: {
      type: Boolean,
      default: false,
    },
    isAWhatsappNotOfficial: {
      type: Boolean,
      default: false,
    },
    currentAssignee: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      isCannedResponseModalOpen: false,
      showEditModal: false,
      showTranslateModal: false,
      showDeleteModal: false,
      showDownload: false,
      id: null,
      conversation_id: null,
      modifiedContent: '',
      showEditOption: false,
      isPendingTranscription: false,
    };
  },

  computed: {
    ...mapGetters({
      getAccount: 'accounts/getAccount',
      currentAccountId: 'getCurrentAccountId',
      allConversations: 'getAllConversations',
      currentUser: 'getCurrentUser',
    }),
    shouldShowDownloadLink() {
      return (
        this.message.attachments &&
        this.message.attachments[0]?.file_type === 'file'
      );
    },
    plainTextContent() {
      return this.getPlainText(this.messageContent);
    },
    showReply() {
      return (
        !this.message?.attachments &&
        (this.inbox?.channel_type === 'Channel::Api' ||
          this.inbox?.channel_type === 'Channel::Whatsapp')
      );
    },
    conversationId() {
      return this.message.conversation_id;
    },
    messageId() {
      return this.message.id;
    },
    messageContent() {
      return this.message.content;
    },
    contentAttributes() {
      return this.message.content_attributes;
    },
    mediaUrl() {
      return !!this.message.attachments;
    },

    isAudioFile(){
      const { enable_transcription } = this.getAccount(this.currentAccountId);
      if(!enable_transcription) return false;
      const audioExtensions = [".mp3", ".wav", ".ogg", ".flac", ".aac", ".m4a", ".wma", ".alac", "audio-ogg", ".oga"];

      try {
        const mediaUrl = this.message?.attachments?.[0]?.data_url;
        const urlObj = new URL(mediaUrl);
        const extension = urlObj.pathname.split('.').pop()?.toLowerCase();
        return extension ? audioExtensions.some(ext => extension.includes(ext.replace(".", ""))) : false;
      } catch (err) {
        console.log(err);
        return false;
      }
    },

    areDatesEqual() {
      if (
        !this.message.updated_at ||
        this.message.created_at === this.message.updated_at
      ) {
        return true;
      }
      let createdAt = new Date(this.message.created_at * 1000);
      let updatedAt = new Date(this.message.updated_at);
      let differenceInSeconds = Math.abs(updatedAt - createdAt) / 1000;
      return differenceInSeconds < 2;
    },
    removeSignatureContent() {
      return this.removeSignature();
    },
    showEdited() {
      return this.showEditedMenu();
    },
  },
  watch: {
    isEditedText(newVal) {
      this.localIsEditedText = newVal;
    },
  },
  methods: {

    async getTranscription(){
      let fileUrl = this.message?.attachments?.[0]?.data_url;
      if(!fileUrl) return;
      this.isPendingTranscription = true;
      try {
        const transcriptionAudio = await this.$store.dispatch('getTranscription', { fileUrl });
        this.message.content = `" ${transcriptionAudio} "`;
      } catch (error) {
        console.log(error);
      } finally{
        this.isPendingTranscription = false;
      }
    },
    isSenderUser() {
      return (
        this.message.message_type == 1 ||
        this.message.message_type == 3 ||
        this.message.sender.type == 'user'
      );
    },
    isWhatsappNotOfficialChannel() {
      return this.isAWhatsappNotOfficial;
    },
    isMessageOld() {
      const messageTime = new Date(this.message.created_at * 1000);
      const currentTime = new Date();
      const diffInMinutes = (currentTime - messageTime) / 60000;
      return diffInMinutes > 10;
    },

    isMessageFromCurrentAgent() {
      return this.message?.sender?.id == this.currentUser?.id;
    },

    removeSignature() {
      if (!this.currentUser || !this.currentAssignee?.message_signature) {
        return this.message.content;
      }
      const firstLine = this.message.content.split('\n')[0];
      const escapedSignature = this.currentAssignee?.message_signature.replace(
        /\*/g,
        '\\*'
      );
      const signaturePattern = new RegExp(`\\*${escapedSignature}\\*`);

      let updatedContent = this.message.content;

      if (signaturePattern.test(firstLine)) {
        const updatedFirstLine = firstLine.replace(signaturePattern, '');

        let lines = updatedContent.split('\n');
        lines[0] = updatedFirstLine;

        lines = lines.slice(2);

        updatedContent = lines.join('\n');
      }

      return updatedContent;
    },
    isAssignedAgent() {
      if (this.currentUser?.id == this.currentAssignee?.id) {
        return true;
      }
      return false;
    },
    showEditedMenu() {
      return (
        !this.isMessageOld() &&
        this.isSenderUser() &&
        this.isWhatsappNotOfficialChannel() &&
        this.isAssignedAgent() &&
        this.isMessageFromCurrentAgent()
      );
    },
    async handleDownload() {
      try {
        this.showAlert(this.$t('CONVERSATION.STARTED_DOWNLOAD'));
        const res = await this.$store.dispatch('downloadMessageFile', {
          messageId: this.messageId,
          conversationId: this.conversationId,
        });
        const data = res.data;
        if (data.file && data.content_type) {
          const link = document.createElement('a');
          const fileExtension = data.content_type.split('/')[1];
          const timestamp = new Date();
          const formattedTimestamp = timestamp
            .toISOString()
            .replace(/[-T:]/g, '-')
            .slice(0, 19);
          link.href = `data:${data.content_type};base64,${data.file}`;
          link.download = `Omnidesk-${formattedTimestamp}.${fileExtension}`;
          document.body.appendChild(link); // Append to body to ensure it works in Firefox
          link.click();
          document.body.removeChild(link); // Clean up
        } else {
          throw new Error(this.$t('CONVERSATION.ERROR_CONTENT_DOWNLOAD'));
        }
      } catch (err) {
        this.showAlert(this.$t('CONVERSATION.ERROR_DOWNLOAD'));
        console.error(err);
      }
    },
    async copyLinkToMessage() {
      const fullConversationURL =
        window.chatwootConfig.hostURL +
        frontendURL(
          conversationUrl({
            id: this.conversationId,
            accountId: this.currentAccountId,
          })
        );
      await copyTextToClipboard(
        `${fullConversationURL}?messageId=${this.messageId}`
      );
      this.showAlert(this.$t('CONVERSATION.CONTEXT_MENU.LINK_COPIED'));
      this.handleClose();
    },
    async handleCopy() {
      await copyTextToClipboard(this.plainTextContent);
      this.showAlert(this.$t('CONTACT_PANEL.COPY_SUCCESSFUL'));
      this.handleClose();
    },
    showCannedResponseModal() {
      this.$track(ACCOUNT_EVENTS.ADDED_TO_CANNED_RESPONSE);
      this.isCannedResponseModalOpen = true;
    },
    hideCannedResponseModal() {
      this.isCannedResponseModalOpen = false;
      this.handleClose();
    },
    handleOpen(e) {
      this.$emit('open', e);
    },
    handleClose(e) {
      this.$emit('close', e);
    },
    handleTranslate() {
      const { locale } = this.getAccount(this.currentAccountId);
      this.$store.dispatch('translateMessage', {
        conversationId: this.conversationId,
        messageId: this.messageId,
        targetLanguage: locale || 'en',
      });
      this.handleClose();
      this.showTranslateModal = true;
    },
    onCloseTranslateModal() {
      this.showTranslateModal = false;
    },
    handleEdit() {
      this.modifiedContent = this.message.content;
      this.handleClose();
      this.showEditModal = true;
    },
    onCloseEditModal() {
      this.showEditModal = false;
    },
    openDeleteModal() {
      this.handleClose();
      this.showDeleteModal = true;
    },
    async replyMessage() {
      const repliedMessage = {
        name: this.message?.sender?.name,
        external_id: this.message?.source_id
          ? this.message?.source_id
          : this.message?.external_id,
        content: this.message?.content,
        additional_attributes: this.message?.additional_attributes,
      };
      this.$emit('externalid', repliedMessage);
      this.handleClose();
    },
    async confirmDeletion() {
      try {
        await this.$store.dispatch('deleteMessage', {
          conversationId: this.conversationId,
          messageId: this.messageId,
        });
        this.showAlert(this.$t('CONVERSATION.SUCCESS_DELETE_MESSAGE'));
        this.handleClose();
      } catch (error) {
        this.showAlert(this.$t('CONVERSATION.FAIL_DELETE_MESSSAGE'));
      }
    },
    closeDeleteModal() {
      this.showDeleteModal = false;
    },
    handleSaveEdit(modifiedContent) {
      this.isEditedText = true;

      let content = modifiedContent;

      if (this.currentUser?.message_signature) {
        content = content.replace(this.currentUser?.message_signature, '');
      }

      this.$store
        .dispatch('editMessage', {
          conversationID: this.conversationId,
          externalId: this.message.external_id,
          content,
          oldContent: this.message.content,
          messageId: this.message.id,
          allConversations: this.allConversations,
          isEdited: this.isEditedText,
        })
        .then(() => {
          this.showAlert(this.$t('CONVERSATION.SUCCESS_EDIT_MESSAGE'));
          this.onValueChange();
        })
        .catch(() => {
          this.showAlert(this.$t('CONVERSATION.FAIL_EDIT_MESSAGE'));
        });
    },
    onValueChange() {
      this.$emit('updateIsEditedText', this.isEditedText);
    },
    handleIsEditedTextUpdate(isEditedText) {
      this.localIsEditedText = isEditedText;
    },
  },
};
</script>
<style lang="scss" scoped>
.menu-container {
  padding: var(--space-smaller);
  background-color: var(--white);
  box-shadow: var(--shadow-context-menu);
  border-radius: var(--border-radius-normal);

  hr:first-child {
    display: none;
  }

  hr {
    border-bottom: 1px solid var(--color-border-light);
    margin: var(--space-smaller);
  }
}

.download-link {
  text-decoration: none;
  color: inherit;
}
.context-menu--delete-modal {
  ::v-deep {
    .modal-container {
      max-width: 48rem;

      h2 {
        font-weight: var(--font-weight-medium);
        font-size: var(--font-size-default);
      }
    }

    .modal-footer {
      padding: var(--space-normal) var(--space-large) var(--space-large);
    }
  }
}
</style>
