<template>
  <div class="row table-wrap">
    <div class="column">
      <woot-tabs :index="selectedTabIndex" @change="onClickTabChange">
        <woot-tabs-item
          v-for="tab in tabs"
          :key="tab.key"
          :name="tab.name"
          :show-badge="false"
        />
      </woot-tabs>

      <div class="columns with-right-space">
        <p
          v-if="
            !uiFlags.isFetching &&
              ((Array.isArray(attributes) && !attributes.length) ||
                (!Array.isArray(attributes) && !attributes))
          "
          class="no-items-error-message"
        >
          {{ $t('ATTRIBUTES_MGMT.LIST.EMPTY_RESULT.404') }}
        </p>
        <woot-loading-state
          v-if="uiFlags.isFetching"
          :message="$t('ATTRIBUTES_MGMT.LOADING')"
        />
        <table
          v-if="!uiFlags.isFetching && attributes.length"
          class="woot-table"
        >
          <thead v-if="selectedTabIndex !== 2">
            <th
              v-for="tableHeader in $t('ATTRIBUTES_MGMT.LIST.TABLE_HEADER')"
              :key="tableHeader"
              class="item"
            >
              {{ tableHeader }}
            </th>
          </thead>

          <thead v-if="selectedTabIndex === 2">
            <th class="item">
              {{ attributes[0].company.name }}
              <woot-button
                v-if="!attributes[0].company.visibility && this.isAdministrator"
                v-tooltip.top="$t('ATTRIBUTES_MGMT.VISUALIZE')"
                variant="clear"
                size="tiny"
                color-scheme="hollow"
                class-names="grey-btn"
                :icon="
                  showData.company || attributes[0].company.visibility
                    ? 'eye-hide'
                    : 'eye-show'
                "
                @click="handleVisibility('company')"
              />
            </th>
            <th class="item">
              {{ attributes[0].client_code.name }}
              <woot-button
                v-if="!attributes[0].client_code.visibility && this.isAdministrator"
                v-tooltip.top="$t('ATTRIBUTES_MGMT.VISUALIZE')"
                variant="clear"
                size="tiny"
                color-scheme="hollow"
                class-names="grey-btn"
                :icon="
                  showData.client_code || attributes[0].client_code.visibility
                    ? 'eye-hide'
                    : 'eye-show'
                "
                @click="handleVisibility('client_code')"
              />
            </th>
            <th class="item">
              {{ attributes[0].status.name }}
              <woot-button
                v-if="!attributes[0].status.visibility && this.isAdministrator"
                v-tooltip.top="$t('ATTRIBUTES_MGMT.VISUALIZE')"
                variant="clear"
                size="tiny"
                color-scheme="hollow"
                class-names="grey-btn"
                :icon="
                  showData.status || attributes[0].status.visibility
                    ? 'eye-hide'
                    : 'eye-show'
                "
                @click="handleVisibility('status')"
              />
            </th>
            <th class="item">
              {{ attributes[0].monthly_fee.name }}
              <woot-button
                v-if="!attributes[0].monthly_fee.visibility && this.isAdministrator"
                v-tooltip.top="$t('ATTRIBUTES_MGMT.VISUALIZE')"
                variant="clear"
                size="tiny"
                color-scheme="hollow"
                class-names="grey-btn"
                :icon="
                  showData.monthly_fee || attributes[0].monthly_fee.visibility
                    ? 'eye-hide'
                    : 'eye-show'
                "
                @click="handleVisibility('monthly_fee')"
              />
            </th>
            <th v-if="attributes[0].custom_field_first.name" class="item">
              {{ attributes[0].custom_field_first.name }}
              <woot-button
                v-if="!attributes[0].custom_field_first.visibility && this.isAdministrator"
                v-tooltip.top="$t('ATTRIBUTES_MGMT.VISUALIZE')"
                variant="clear"
                size="tiny"
                color-scheme="hollow"
                class-names="grey-btn"
                :icon="
                  showData.custom_field_first ||
                  attributes[0].custom_field_first.visibility
                    ? 'eye-hide'
                    : 'eye-show'
                "
                @click="handleVisibility('custom_field_first')"
              />
            </th>
            <th v-if="attributes[0].custom_field_second.name" class="item">
              {{ attributes[0].custom_field_second.name }}
              <woot-button
                v-if="!attributes[0].custom_field_second.visibility  && this.isAdministrator"
                v-tooltip.top="$t('ATTRIBUTES_MGMT.VISUALIZE')"
                variant="clear"
                size="tiny"
                color-scheme="hollow"
                class-names="grey-btn"
                :icon="
                  showData.custom_field_second ||
                  attributes[0].custom_field_second.visibility
                    ? 'eye-hide'
                    : 'eye-show'
                "
                @click="handleVisibility('custom_field_second')"
              />
            </th>
            <div class="table-tools-container">
              <woot-button
                v-tooltip.top="$t('ATTRIBUTES_MGMT.EDIT_TABLE')"
                variant="clear"
                size="small"
                color-scheme="hollow"
                class-names="grey-btn"
                icon="settings"
                @click="openEditTablePopup"
              />
              <woot-button
                v-tooltip.top="$t('ATTRIBUTES_MGMT.DELETE.BUTTON_TEXT')"
                variant="smooth"
                size="small"
                color-scheme="alert"
                class-names="grey-btn"
                class="btn-margin"
                icon="dismiss-circle"
                @click="openDeleteTable"
              />
            </div>
          </thead>
          <tbody v-if="selectedTabIndex !== 2">
            <tr v-for="attribute in attributes" :key="attribute.attribute_key">
              <td class="item text-truncate">
                {{ attribute.attribute_display_name }}
              </td>
              <td class="item-description text-truncate">
                {{ attribute.attribute_description }}
              </td>
              <td class="item text-truncatee">
                {{ attribute.attribute_display_type }}
              </td>
              <td class="item key text-truncate">
                {{ attribute.attribute_key }}
              </td>
              <td class="button-wrapper">
                <woot-button
                  v-tooltip.top="$t('ATTRIBUTES_MGMT.LIST.BUTTONS.EDIT')"
                  variant="smooth"
                  size="tiny"
                  color-scheme="secondary"
                  class-names="grey-btn"
                  icon="edit"
                  @click="openEditPopup(attribute)"
                />
                <woot-button
                  v-tooltip.top="$t('ATTRIBUTES_MGMT.LIST.BUTTONS.DELETE')"
                  variant="smooth"
                  color-scheme="alert"
                  size="tiny"
                  icon="dismiss-circle"
                  class-names="grey-btn"
                  @click="openDelete(attribute)"
                />
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr
              v-for="attribute in attributes[0].group_attributes"
              :key="attribute.id"
            >
              <td class="item text-truncate">
                <span
                  v-if="showData.company || attributes[0].company.visibility"
                >
                  {{ attribute.company }}
                </span>
                <span v-else>
                  ***
                </span>
              </td>
              <td class="item text-truncate">
                <span
                  v-if="
                    showData.client_code || attributes[0].client_code.visibility
                  "
                >
                  {{ attribute.client_code }}
                </span>
                <span v-else>
                  ***
                </span>
              </td>
              <td class="item text-truncate">
                <span v-if="showData.status || attributes[0].status.visibility">
                  {{ attribute.status }}
                </span>
                <span v-else>
                  ***
                </span>
              </td>
              <td class="item text-truncate">
                <span
                  v-if="
                    showData.monthly_fee || attributes[0].monthly_fee.visibility
                  "
                >
                  {{ attribute.monthly_fee }}
                </span>
                <span v-else>
                  ***
                </span>
              </td>
              <td
                v-if="attributes[0].custom_field_first.name"
                class="item text-truncate"
              >
                <span
                  v-if="
                    showData.custom_field_first ||
                      attributes[0].custom_field_first.visibility
                  "
                >
                  {{ attribute.custom_field_first }}
                </span>
                <span v-else>
                  ***
                </span>
              </td>
              <td
                v-if="attributes[0].custom_field_second.name"
                class="item text-truncate"
              >
                <span
                  v-if="
                    showData.custom_field_second ||
                      attributes[0].custom_field_second.visibility
                  "
                >
                  {{ attribute.custom_field_second }}
                </span>
                <span v-else>
                  ***
                </span>
              </td>
              <td class="button-wrapper">
                <woot-button
                  v-if="this.isAdministrator"
                  v-tooltip.top="$t('ATTRIBUTES_MGMT.LIST.BUTTONS.EDIT')"
                  variant="smooth"
                  size="tiny"
                  color-scheme="secondary"
                  class-names="grey-btn"
                  icon="edit"
                  @click="openEditGroupPopup(attribute)"
                />
                <woot-button
                  v-if="this.isAdministrator"
                  v-tooltip.top="$t('ATTRIBUTES_MGMT.LIST.BUTTONS.DELETE')"
                  variant="smooth"
                  color-scheme="alert"
                  size="tiny"
                  icon="dismiss-circle"
                  class-names="grey-btn"
                  @click="openDeleteGroup(attribute)"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="small-4 columns">
      <span v-dompurify-html="$t('ATTRIBUTES_MGMT.SIDEBAR_TXT')" />
    </div>
    <woot-modal :show.sync="showEditPopup" :on-close="hideEditPopup">
      <edit-attribute
        :selected-attribute="selectedAttribute"
        :is-updating="uiFlags.isUpdating"
        @on-close="hideEditPopup"
      />
    </woot-modal>
    <woot-modal :show.sync="showEditTablePopup" :on-close="hideEditTablePopup">
      <edit-table
        :selected-table="attributes[0]"
        :is-updating="uiFlags.isUpdating"
        @on-close="hideEditTablePopup"
      />
    </woot-modal>
    <woot-modal :show.sync="showEditGroupPopup" :on-close="hideEditGroupPopup">
      <edit-group-attribute
        :selected-attribute="selectedAttribute"
        :selected-table="attributes[0]"
        :is-updating="uiFlags.isUpdating"
        @on-close="hideEditGroupPopup"
      />
    </woot-modal>
    <woot-confirm-delete-modal
      v-if="showDeletePopup"
      :show.sync="showDeletePopup"
      :title="confirmDeleteTitle"
      :message="$t('ATTRIBUTES_MGMT.DELETE.CONFIRM.MESSAGE')"
      :confirm-text="deleteConfirmText"
      :reject-text="deleteRejectText"
      :confirm-value="selectedAttribute.attribute_display_name"
      :confirm-place-holder-text="confirmPlaceHolderText"
      @on-confirm="confirmDeletion"
      @on-close="closeDelete"
    />
    <woot-confirm-delete-modal
      v-if="showDeleteGroupPopup"
      :show.sync="showDeleteGroupPopup"
      :title="confirmGroupDeleteTitle"
      :message="$t('ATTRIBUTES_MGMT.DELETE.CONFIRM.MESSAGE')"
      :confirm-text="deleteGroupConfirmText"
      :reject-text="deleteRejectText"
      :confirm-value="selectedAttribute.company"
      :confirm-place-holder-text="confirmGroupPlaceHolderText"
      @on-confirm="confirmGroupDeletion"
      @on-close="closeDeleteGroup"
    />
    <woot-confirm-delete-modal
      v-if="showDeleteTablePopup"
      :show.sync="showDeleteTablePopup"
      :title="confirmTableDeleteTitle"
      :message="$t('ATTRIBUTES_MGMT.DELETE.CONFIRM.MESSAGE')"
      :confirm-text="deleteTableConfirmText"
      :reject-text="deleteRejectText"
      :confirm-value="attributes[0].name"
      :confirm-place-holder-text="confirmTablePlaceHolderText"
      @on-confirm="confirmTableDeletion"
      @on-close="closeDeleteGroup"
    />
  </div>
</template>

<script>
import alertMixin from 'shared/mixins/alertMixin';
import { mapGetters } from 'vuex';
import WootButton from '../../../../components/ui/WootButton.vue';
import EditAttribute from './EditAttribute';
import EditGroupAttribute from './EditGroupAttribute.vue';
import EditTable from './EditTable.vue';

export default {
  components: {
    EditAttribute,
    EditGroupAttribute,
    EditTable,
    WootButton,
  },
  mixins: [alertMixin],
  data() {
    return {
      selectedTabIndex: 0,
      showEditPopup: false,
      showEditTablePopup: false,
      showEditGroupPopup: false,
      showDeletePopup: false,
      showDeleteGroupPopup: false,
      showDeleteTablePopup: false,
      selectedAttribute: {},
      showData: {},
    };
  },
  computed: {
    ...mapGetters({
      uiFlags: 'attributes/getUIFlags',
      currentUser: 'getCurrentUser',
    }),
    attributes() {
      let attributeModel = '';

      if (this.selectedTabIndex === 0) {
        attributeModel = 'conversation_attribute';
      } else if (this.selectedTabIndex === 1) {
        attributeModel = 'contact_attribute';
      } else {
        attributeModel = 'group_attribute';
      }
      const customAttribute = this.$store.getters[
        'attributes/getAttributesByModel'
      ](attributeModel);
      return customAttribute;
    },
    tabs() {
      return [
        {
          key: 0,
          name: this.$t('ATTRIBUTES_MGMT.TABS.CONVERSATION'),
        },
        {
          key: 1,
          name: this.$t('ATTRIBUTES_MGMT.TABS.CONTACT'),
        },
        {
          key: 2,
          name: 'Grupos',
        },
      ];
    },
    deleteConfirmText() {
      return `${this.$t('ATTRIBUTES_MGMT.DELETE.CONFIRM.YES')} ${
        this.selectedAttribute.attribute_display_name
      }`;
    },
    deleteGroupConfirmText() {
      return `${this.$t('ATTRIBUTES_MGMT.DELETE.CONFIRM.YES')} ${
        this.selectedAttribute?.company
      }`;
    },
    deleteTableConfirmText() {
      return `${this.$t('ATTRIBUTES_MGMT.DELETE.CONFIRM.YES')} ${
        this.attributes[0]?.name
      }`;
    },
    deleteRejectText() {
      return this.$t('ATTRIBUTES_MGMT.DELETE.CONFIRM.NO');
    },
    confirmDeleteTitle() {
      return this.$t('ATTRIBUTES_MGMT.DELETE.CONFIRM.TITLE', {
        attributeName: this.selectedAttribute.attribute_display_name,
      });
    },
    confirmGroupDeleteTitle() {
      return this.$t('ATTRIBUTES_MGMT.DELETE.CONFIRM.TITLE', {
        attributeName: this.selectedAttribute?.company,
      });
    },
    confirmTableDeleteTitle() {
      return this.$t('ATTRIBUTES_MGMT.DELETE.CONFIRM.TITLE', {
        attributeName: this.attributes[0]?.name,
      });
    },
    confirmPlaceHolderText() {
      return `${this.$t('ATTRIBUTES_MGMT.DELETE.CONFIRM.PLACE_HOLDER', {
        attributeName: this.selectedAttribute.attribute_display_name,
      })}`;
    },
    confirmGroupPlaceHolderText() {
      return `${this.$t('ATTRIBUTES_MGMT.DELETE.CONFIRM.PLACE_HOLDER', {
        attributeName: this.selectedAttribute?.company,
      })}`;
    },
    confirmTablePlaceHolderText() {
      return `${this.$t('ATTRIBUTES_MGMT.DELETE.CONFIRM.PLACE_HOLDER', {
        attributeName: this.attributes[0]?.name,
      })}`;
    },
  },
  mounted() {
    this.fetchAttributes(this.selectedTabIndex);
  },
  methods: {
    getVisibility(field) {
      return this.attributes[0]?.[field]?.visibility;
    },
    itemVisibility() {
      return this.isAdministrator
    },

    toggleItemVisibility(field) {
      if (this.attributes[0]?.[field]) {
        this.attributes[0][field].visibility = !this.attributes[0][field]
          .visibility;
      }
    },
    onClickTabChange(index) {
      this.selectedTabIndex = index;
      this.$store.dispatch('attributes/setTab', index);
      this.fetchAttributes(index);
    },
    handleVisibility(field) {
      if (!this.showData) {
        this.showData = {};
      }
      if (this.isAdministrator) {
        this.$set(this.showData, field, !this.showData[field]);
      }
    },
    fetchAttributes(index) {
      this.$store.dispatch('attributes/get', index);
    },
    async deleteAttributes({ id }) {
      try {
        await this.$store.dispatch('attributes/delete', id);
        this.showAlert(this.$t('ATTRIBUTES_MGMT.DELETE.API.SUCCESS_MESSAGE'));
      } catch (error) {
        const errorMessage =
          error?.response?.message ||
          this.$t('ATTRIBUTES_MGMT.DELETE.API.ERROR_MESSAGE');
        this.showAlert(errorMessage);
      }
    },
    async deleteGroupAttributes({ id }) {
      try {
        await this.$store.dispatch('attributes/delete', {
          id: id,
          attributeType: 'group_attribute',
        });
        this.showAlert(this.$t('ATTRIBUTES_MGMT.DELETE.API.SUCCESS_MESSAGE'));
        this.fetchAttributes(this.selectedTabIndex);
      } catch (error) {
        const errorMessage =
          error?.response?.message ||
          this.$t('ATTRIBUTES_MGMT.DELETE.API.ERROR_MESSAGE');
        this.showAlert(errorMessage);
      }
    },
    async deleteTable(id) {
      try {
        await this.$store.dispatch('attributes/delete', {
          id: id,
          attributeType: 'group_table',
        });
        this.showAlert(this.$t('ATTRIBUTES_MGMT.DELETE.API.SUCCESS_MESSAGE'));
        this.fetchAttributes(this.selectedTabIndex);
      } catch (error) {
        const errorMessage =
          error?.response?.message ||
          this.$t('ATTRIBUTES_MGMT.DELETE.API.ERROR_MESSAGE');
        this.showAlert(errorMessage);
      }
    },
    openEditPopup(response) {
      this.showEditPopup = true;
      this.selectedAttribute = response;
    },
    openEditTablePopup() {
      this.showEditTablePopup = true;
    },
    hideEditTablePopup() {
      this.showEditTablePopup = false;
      this.fetchAttributes(this.selectedTabIndex);
    },
    hideEditPopup() {
      this.showEditPopup = false;
    },
    openEditGroupPopup(response) {
      this.showEditGroupPopup = true;
      this.selectedAttribute = response;
    },
    hideEditGroupPopup() {
      this.showEditGroupPopup = false;
      this.fetchAttributes(this.selectedTabIndex);
    },
    confirmDeletion() {
      this.deleteAttributes(this.selectedAttribute);
      this.closeDelete();
    },
    confirmGroupDeletion() {
      this.deleteGroupAttributes(this.selectedAttribute);
      this.closeDeleteGroup();
    },
    confirmTableDeletion() {
      this.deleteTable(this.attributes[0].id);
      this.closeDeleteTable();
    },
    openDelete(value) {
      this.showDeletePopup = true;
      this.selectedAttribute = value;
    },
    openDeleteGroup(value) {
      this.showDeleteGroupPopup = true;
      this.selectedAttribute = value;
    },
    openDeleteTable() {
      this.showDeleteTablePopup = true;
    },
    closeDelete() {
      this.showDeletePopup = false;
      this.selectedAttribute = {};
    },
    closeDeleteGroup() {
      this.showDeleteGroupPopup = false;
      this.selectedAttribute = {};
    },
    closeDeleteTable() {
      this.showDeleteTablePopup = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.table-wrap {
  padding-left: var(--space-small);
}

.woot-table {
  width: 100%;
  margin-top: var(--space-small);
  table-layout: fixed;
}

.no-items-error-message {
  margin-top: var(--space-larger);
}

.tabs {
  padding-left: 0;
  margin-right: var(--space-medium);
  user-select: none;
}

.item {
  padding-left: 0;
  max-width: 10rem;
  min-width: 8rem;
}

.item-description {
  padding-left: 0;
  max-width: 16rem;
  min-width: 10rem;
}
.table-tools-container {
  margin-left: 18px;
}

.btn-margin {
  margin-left: 5px;
}

.key {
  font-family: monospace;
}

::v-deep {
  .tabs-title a {
    font-weight: var(--font-weight-medium);
    padding-top: 0;
  }
}
</style>
