<template>
  <woot-modal :show.sync="show" :on-close="onClose">
    <div class="column content-box">
      <woot-modal-header
        :header-title="$t('AGENT_MGMT.ADD.TITLE')"
        :header-content="$t('AGENT_MGMT.ADD.DESC')"
      />

      <form class="row" @submit.prevent="addAgent()">
        <div class="medium-12 columns">
          <label :class="{ error: $v.agentName.$error }">
            {{ $t('AGENT_MGMT.ADD.FORM.NAME.LABEL') }}
            <input
              v-model.trim="agentName"
              type="text"
              :placeholder="$t('AGENT_MGMT.ADD.FORM.NAME.PLACEHOLDER')"
              @input="$v.agentName.$touch"
            />
          </label>
        </div>
        <div class="medium-12 columns">
          <label :class="{ error: $v.agentType.$error }">
            {{ $t('AGENT_MGMT.ADD.FORM.AGENT_TYPE.LABEL') }}
            <select v-model="agentType" @change="changeTypeRole">
              <option v-for="role in roles" :key="role.name" :value="role.name">
                {{ role.label }}
              </option>
            </select>
            <span v-if="$v.agentType.$error" class="message">
              {{ $t('AGENT_MGMT.ADD.FORM.AGENT_TYPE.ERROR') }}
            </span>
          </label>
        </div>

        <div class="medium-12 columns">
          <label class="medium-12 columns">
            <div class="enter-to-send--checkbox">
              <label>
                {{ $t('AGENT_MGMT.PERMISSIONS.TITLE') }}
              </label>
            </div>
          </label>
        </div>

        <div v-if="agentType === 'supervisor' || agentType === 'agent'">
          <div
            v-for="permision in permissions"
            :key="permision.name"
            class="medium-12 columns"
          >
            <label class="medium-12 columns">
              <div>
                <input
                  v-model="permissionsSelected[permision.name]"
                  type="checkbox"
                />
                <label>
                  {{ permision.label }}
                </label>
              </div>
            </label>
          </div>
        </div>

        <div v-if="agentType === 'administrator'" class="medium-12 columns">
          <label class="medium-12 columns">
            <div class="enter-to-send--checkbox">
              <label class="full-access">
                {{ this.$t('AGENT_MGMT.PERMISSIONS.FULL_ACCESS') }}
              </label>
            </div>
          </label>
        </div>

        <div class="medium-12 columns">
          <label :class="{ error: $v.agentEmail.$error }">
            {{ $t('AGENT_MGMT.ADD.FORM.EMAIL.LABEL') }}
            <input
              v-model.trim="agentEmail"
              type="text"
              :placeholder="$t('AGENT_MGMT.ADD.FORM.EMAIL.PLACEHOLDER')"
              @input="$v.agentEmail.$touch"
            />
          </label>
        </div>
        <div v-if="!sendValidateEmail" class="medium-12 columns">
          <woot-input
            v-model="password"
            type="password"
            :class="{ error: $v.password.$error }"
            :label="$t('PROFILE_SETTINGS.FORM.PASSWORD.LABEL')"
            :placeholder="$t('PROFILE_SETTINGS.FORM.PASSWORD.PLACEHOLDER')"
            :error="
              $v.password.$error
                ? $t('PROFILE_SETTINGS.FORM.PASSWORD.ERROR')
                : ''
            "
            @blur="$v.password.$touch"
          />
          <woot-input
            v-model="passwordConfirmation"
            type="password"
            :class="{ error: $v.passwordConfirmation.$error }"
            :label="$t('PROFILE_SETTINGS.FORM.PASSWORD_CONFIRMATION.LABEL')"
            :placeholder="
              $t('PROFILE_SETTINGS.FORM.PASSWORD_CONFIRMATION.PLACEHOLDER')
            "
            :error="
              $v.passwordConfirmation.$error
                ? $t('PROFILE_SETTINGS.FORM.PASSWORD_CONFIRMATION.ERROR')
                : ''
            "
            @blur="$v.passwordConfirmation.$touch"
          />
        </div>
        <div class="medium-12 columns">
          <label class="medium-12 columns">
            <div class="enter-to-send--checkbox">
              <input
                id="sendValidateEmail"
                v-model="sendValidateEmail"
                type="checkbox"
                @change="handleCheckboxChange()"
              />
              <label for="sendValidateEmail">
                {{ $t('INBOX_MGMT.SETTINGS_POPUP.SEND_VALIDATE_EMAIL') }}
              </label>
            </div>
          </label>
        </div>
        <div class="modal-footer">
          <div class="medium-12 columns">
            <woot-submit-button
              class="bg-primary"
              :disabled="disableButton()"
              :button-text="$t('AGENT_MGMT.ADD.FORM.SUBMIT')"
              :loading="uiFlags.isCreating"
            />
            <button class="button clear btn-primary" @click.prevent="onClose">
              {{ $t('AGENT_MGMT.ADD.CANCEL_BUTTON_TEXT') }}
            </button>
          </div>
        </div>
      </form>
    </div>
  </woot-modal>
</template>

<script>
import {
  email,
  helpers,
  minLength,
  required,
  sameAs,
} from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';

const passwordCheck = helpers.withParams({ type: 'passwordCheck' }, value => {
  if (!value) return true;
  const pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
  return pattern.test(value);
});

export default {
  props: {
    onClose: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      agentName: '',
      agentEmail: '',
      agentType: 'agent',
      password: '',
      passwordConfirmation: '',
      sendValidateEmail: false,
      vertical: 'bottom',
      horizontal: 'center',
      roles: [
        {
          name: 'agent',
          label: this.$t('AGENT_MGMT.AGENT_TYPES.AGENT'),
        },
        {
          name: 'supervisor',
          label: this.$t('AGENT_MGMT.AGENT_TYPES.SUPERVISOR'),
        },
        {
          name: 'administrator',
          label: this.$t('AGENT_MGMT.AGENT_TYPES.ADMINISTRATOR'),
        },
      ],
      show: true,
      permissionsAgent: [
        {
          name: 'create_global_shortcut',
          label: this.$t(
            'AGENT_MGMT.PERMISSIONS.CREATE_GLOBAL_SHORTCUT_SETTINGS'
          ),
        },
        {
          name: 'see_private_message',
          label: this.$t('AGENT_MGMT.PERMISSIONS.SEE_PRIVATE_MESSAGE_SETTINGS'),
        },
        {
          name: 'assign_to_me',
          label: this.$t('AGENT_MGMT.PERMISSIONS.ASSIGN_TO_ME'),
        },
        {
          name: 'see_all_conversations',
          label: this.$t('AGENT_MGMT.PERMISSIONS.SEE_ALL_CONVERSATIONS'),
        },
        {
          name: 'see_conversations_team',
          label: this.$t('AGENT_MGMT.PERMISSIONS.SEE_CONVERSATIONS_TEAM'),
        },
        {
          name: 'see_bot_conversation',
          label: this.$t('AGENT_MGMT.PERMISSIONS.SEE_BOT_CONVERSATION'),
        },
        {
          name: 'group_attributes',
          label: this.$t('AGENT_MGMT.PERMISSIONS.GROUP_ATTRIBUTES'),
        },
        {
          name: 'delete_crm_observation',
          label: this.$t('AGENT_MGMT.PERMISSIONS.DELETE_CRM_OBSERVATION'),
        },
        {
          name: 'manager_catalog_product',
          label: this.$t('AGENT_MGMT.PERMISSIONS.MANAGER_CATALOG_PRODUCT'),
        },
      ],
      permissionsSupervisor: [
        {
          name: 'dashboard',
          label: this.$t('AGENT_MGMT.PERMISSIONS.DASHBOARD'),
        },
        { name: 'contacts', label: this.$t('AGENT_MGMT.PERMISSIONS.CONTACTS') },
        {
          name: 'campaigns',
          label: this.$t('AGENT_MGMT.PERMISSIONS.CAMPAIGNS'),
        },
        {
          name: 'help_center',
          label: this.$t('AGENT_MGMT.PERMISSIONS.HELP_CENTER'),
        },
        { name: 'reports', label: this.$t('AGENT_MGMT.PERMISSIONS.REPORTS') },
        {
          name: 'agent_settings',
          label: this.$t('AGENT_MGMT.PERMISSIONS.AGENT_SETTINGS'),
        },
        {
          name: 'team_settings',
          label: this.$t('AGENT_MGMT.PERMISSIONS.TEAM_SETTINGS'),
        },
        {
          name: 'markers_settings',
          label: this.$t('AGENT_MGMT.PERMISSIONS.MARKERS_SETTINGS'),
        },
        {
          name: 'custom_attributes_settings',
          label: this.$t('AGENT_MGMT.PERMISSIONS.CUSTOM_ATTRIBUTES_SETTINGS'),
        },
        {
          name: 'automations_settings',
          label: this.$t('AGENT_MGMT.PERMISSIONS.AUTOMATIONS_SETTINGS'),
        },
        {
          name: 'macros_settings',
          label: this.$t('AGENT_MGMT.PERMISSIONS.MACROS_SETTINGS'),
        },
        {
          name: 'shortcuts_settings',
          label: this.$t('AGENT_MGMT.PERMISSIONS.SHORTCUTS_SETTINGS'),
        },
        {
          name: 'create_global_shortcut',
          label: this.$t(
            'AGENT_MGMT.PERMISSIONS.CREATE_GLOBAL_SHORTCUT_SETTINGS'
          ),
        },
        {
          name: 'see_private_message',
          label: this.$t('AGENT_MGMT.PERMISSIONS.SEE_PRIVATE_MESSAGE_SETTINGS'),
        },
        {
          name: 'team_chat_only',
          label: this.$t('AGENT_MGMT.PERMISSIONS.VIEW_TEAM'),
        },
        { name: 'inboxes', label: this.$t('AGENT_MGMT.PERMISSIONS.INBOXES') },
        {
          name: 'delete_inbox',
          label: this.$t('AGENT_MGMT.PERMISSIONS.DELETE_INBOX'),
        },
        {
          name: 'delete_agent',
          label: this.$t('AGENT_MGMT.PERMISSIONS.DELETE_AGENT'),
        },
        {
          name: 'delete_team',
          label: this.$t('AGENT_MGMT.PERMISSIONS.DELETE_TEAM'),
        },
        {
          name: 'integrations',
          label: this.$t('AGENT_MGMT.PERMISSIONS.INTEGRATIONS'),
        },
        {
          name: 'applications',
          label: this.$t('AGENT_MGMT.PERMISSIONS.APPLICATIONS'),
        },
        {
          name: 'audit_records',
          label: this.$t('AGENT_MGMT.PERMISSIONS.AUDIT_RECORDS'),
        },
        {
          name: 'assign_to_me',
          label: this.$t('AGENT_MGMT.PERMISSIONS.ASSIGN_TO_ME'),
        },
        {
          name: 'see_all_conversations',
          label: this.$t('AGENT_MGMT.PERMISSIONS.SEE_ALL_CONVERSATIONS'),
        },
        {
          name: 'delete_contact',
          label: this.$t('AGENT_MGMT.PERMISSIONS.DELETE_CONTACT'),
        },
        {
          name: 'see_bot_conversation',
          label: this.$t('AGENT_MGMT.PERMISSIONS.SEE_BOT_CONVERSATION'),
        },
        {
          name: 'group_attributes',
          label: this.$t('AGENT_MGMT.PERMISSIONS.GROUP_ATTRIBUTES'),
        },
        {
          name: 'delete_crm_observation',
          label: this.$t('AGENT_MGMT.PERMISSIONS.DELETE_CRM_OBSERVATION'),
        },
        {
          name: 'manager_catalog_product',
          label: this.$t('AGENT_MGMT.PERMISSIONS.MANAGER_CATALOG_PRODUCT'),
        },
      ],
      permissions: [],
      permissionsSelected: {
        dashboard: true,
        contacts: true,
        campaigns: true,
        help_center: true,
        reports: true,
        agent_settings: true,
        team_settings: true,
        markers_settings: true,
        custom_attributes_settings: true,
        automations_settings: true,
        macros_settings: true,
        shortcuts_settings: true,
        create_global_shortcut: true,
        see_private_message: true,
        inboxes: false,
        delete_inbox: false,
        delete_agent: false,
        delete_team: false,
        integrations: false,
        applications: false,
        audit_records: false,
        assign_to_me: false,
        see_all_conversations: false,
        delete_contact: false,
        team_chat_only: true,
        see_conversations_team: false,
        delete_crm_observation: false,
        manager_catalog_product: false,
        crm_settings: false,
        see_all_crm_tickets: false,
        group_attributes: false,
      },
      permissionsDefault: {
        dashboard: true,
        contacts: true,
        campaigns: true,
        help_center: true,
        reports: true,
        agent_settings: true,
        team_settings: true,
        markers_settings: true,
        custom_attributes_settings: true,
        automations_settings: true,
        macros_settings: true,
        shortcuts_settings: true,
        create_global_shortcut: true,
        see_private_message: true,
        inboxes: false,
        delete_inbox: false,
        delete_agent: false,
        delete_team: false,
        integrations: false,
        applications: false,
        audit_records: false,
        assign_to_me: false,
        see_all_conversations: false,
        delete_contact: false,
        team_chat_only: true,
        see_conversations_team: false,
        delete_crm_observation: false,
        manager_catalog_product: false,
        crm_settings: false,
        see_all_crm_tickets: false,
        group_attributes: false,
      },
    };
  },
  computed: {
    ...mapGetters({
      uiFlags: 'agents/getUIFlags',
      getAccount: 'accounts/getAccount',
      currentAccountId: 'getCurrentAccountId',
    }),
  },
  watch: {
    'permissionsSelected.see_conversations_team'(newValue) {
      if (newValue) {
        this.permissionsSelected.see_all_conversations = false;
      }
    },
    'permissionsSelected.see_all_conversations'(newValue) {
      if (newValue) {
        this.permissionsSelected.see_conversations_team = false;
      }
    },
  },
  validations: {
    agentName: {
      required,
      minLength: minLength(1),
    },
    agentEmail: {
      required,
      email,
    },
    agentType: {
      required,
    },
    password: {
      required,
      passwordCheck,
    },
    passwordConfirmation: {
      minLength: minLength(6),
      sameAsPassword: sameAs('password'),
    },
  },

  mounted() {
    this.permissions = this.permissionsAgent;
    const account = this.getAccount(this.currentAccountId);

    if (account?.enable_crm) {
      const crm_settings = {
        name: 'crm_settings',
        label: this.$t('AGENT_MGMT.PERMISSIONS.CRM_SETTINGS'),
      };
      const see_all_crm_tickets = {
        name: 'see_all_crm_tickets',
        label: this.$t('AGENT_MGMT.PERMISSIONS.SEE_ALL_CRM_TICKETS'),
      };
      this.permissionsAgent.push(...[crm_settings, see_all_crm_tickets]);
      this.permissionsSupervisor.push(...[crm_settings, see_all_crm_tickets]);
    }
  },

  methods: {
    showAlert(message) {
      bus.$emit('newToastMessage', message);
    },
    disableButton() {
      if (!this.sendValidateEmail) {
        return (
          this.$v.agentName.$invalid ||
          this.$v.agentEmail.$invalid ||
          this.$v.password.$invalid ||
          this.$v.passwordConfirmation.$invalid ||
          this.uiFlags.isCreating
        );
      }
      return (
        this.$v.agentEmail.$invalid ||
        this.$v.agentName.$invalid ||
        this.uiFlags.isCreating
      );
    },
    handleCheckboxChange() {
      this.password = '';
      this.passwordConfirmation = '';
    },

    validatePermissions() {
      const agentPermissions = [
        'assign_to_me',
        'see_all_conversations',
        'shortcuts_settings',
        'create_global_shortcut',
        'see_private_message',
        'see_conversations_team',
        'see_bot_conversation',
        'delete_crm_observation',
        'manager_catalog_product',
        'crm_settings',
        'see_all_crm_tickets'
      ];
      // eslint-disable-next-line no-restricted-syntax
      for (let permission in this.permissionsSelected) {
        if (this.agentType === 'agent') {
          if (!agentPermissions.includes(permission)) {
            this.permissionsSelected[permission] = false;
          }
        } else if (this.agentType === 'administrator') {
          if (permission === 'team_chat_only') {
            this.permissionsSelected[permission] = false;
          } else {
            this.permissionsSelected[permission] = true;
          }
        }
      }
      return this.permissionsSelected;
    },

    async addAgent() {
      const permissions = this.validatePermissions();

      try {
        await this.$store.dispatch('agents/create', {
          name: this.agentName,
          email: this.agentEmail,
          role: this.agentType === 'agent' ? 'agent' : 'administrator',
          type_role: this.agentType,
          permissions: permissions,
          password: this.password,
          send_validate_email: this.sendValidateEmail,
        });
        this.showAlert(this.$t('AGENT_MGMT.ADD.API.SUCCESS_MESSAGE'));
        this.onClose();
      } catch (error) {
        const {
          response: { data: { error: errorResponse = '' } = {} } = {},
        } = error;
        let errorMessage = '';
        if (error.response.status === 422) {
          if (error.response.data.attributes.includes('email')) {
            errorMessage = this.$t('AGENT_MGMT.ADD.API.EXIST_MESSAGE');
          } else if (error.response.data.attributes.includes('password')) {
            errorMessage = this.$t(
              'AGENT_MGMT.ADD.API.PASSWORD_REQUIREMENTS_MESSAGE'
            );
          }
        } else {
          errorMessage = this.$t('AGENT_MGMT.ADD.API.ERROR_MESSAGE');
        }
        this.showAlert(errorResponse || errorMessage);
      }
    },

    changeTypeRole(e) {
      const typeRole = e.target.value;
      this.permissionsSelected = JSON.parse(
        JSON.stringify(this.permissionsDefault)
      );

      if (typeRole === 'agent') {
        this.permissions = this.permissionsAgent;
      } else {
        this.permissions = this.permissionsSupervisor;
      }
    },
  },
};
</script>

<style scoped>
.full-access {
  color: #01c6d8 !important;
}
</style>
