<template>
  <div ref="fullscreenDiv" class="main" :class="{ fullscreen: isFullscreen }">
    <div class="menu-header">
      <h1 class="page-title">
        <i class="icon fi fi-sr-layout-fluid" />
        <slot />
        <span>Dashboard</span>
      </h1>
      <button class="screen" @click="toggleFullscreen">
        <img
          :src="require('../../../assets/images/fullscreen.png')"
          alt="fullscreen"
        />
      </button>
    </div>
    <div class="content-box">
      <div class="flex row">
        <metric-card
          class="row"
          :header="this.$t('DASHBOARD.CONVERSATION.TITLE')"
        >
          <div class="metric-content column">
            <h3 class="heading emphasis">
              {{ accountConversationMetric.open || 0 }}
            </h3>
            <p class="metric">{{ this.$t('DASHBOARD.CONVERSATION.OPEN') }}</p>
          </div>
          <div class="metric-content column">
            <h3 class="heading red">
              {{ accountConversationMetric.unattended || 0 }}
            </h3>
            <p class="metric">
              {{ this.$t('DASHBOARD.CONVERSATION.NO_SERVED') }}
            </p>
          </div>
          <div class="metric-content column">
            <h3 class="heading">
              {{ accountConversationMetric.unassigned || 0 }}
            </h3>
            <p class="metric">
              {{ this.$t('DASHBOARD.CONVERSATION.NOT_ASSIGNED') }}
            </p>
          </div>
        </metric-card>
        <metric-card
          class="column"
          :header="this.$t('DASHBOARD.BOT_CONVERSATIONS')"
        >
          <div class="metric-content column">
            <h3 class="heading">
              {{ accountSummary.bot_dashboard_conversation_count }}
            </h3>
            <div class="status">
              <p class="metric">
                {{ this.$t('DASHBOARD.ACTIVE') }}
              </p>
            </div>
          </div>
          <div class="metric-content column">
            <h3 class="heading">
              {{ accountSummary.bot_dashboard_resolution_count }}
            </h3>
            <div class="status">
              <p class="metric">
                {{ this.$t('DASHBOARD.SOLVED') }}
              </p>
            </div>
          </div>
        </metric-card>
        <metric-card
          class="column"
          :header="this.$t('DASHBOARD.AGENTS_STATUS')"
        >
          <div class="metric-content column">
            <h3 class="heading">{{ agentStatus.online }}</h3>
            <div class="status">
              <div class="cicle on" />
              <p class="metric">
                {{ this.$t('DASHBOARD.ONLINE') }}
              </p>
            </div>
          </div>
          <div class="metric-content column">
            <h3 class="heading">{{ agentStatus.busy }}</h3>
            <div class="status">
              <div class="cicle busy" />
              <p class="metric">
                {{ this.$t('DASHBOARD.BUSY') }}
              </p>
            </div>
          </div>
          <div class="metric-content column">
            <h3 class="heading">{{ agentStatus.offline }}</h3>
            <div class="status">
              <div class="cicle off" />
              <p class="metric">
                {{ this.$t('DASHBOARD.OFFLINE') }}
              </p>
            </div>
          </div>
        </metric-card>
      </div>
      <div class="flex row">
        <metric-card class="row" :header="this.$t('DASHBOARD.TODAY.TITLE')">
          <div class="metric-content column">
            <h3 class="heading">{{ accountSummary.conversations_count }}</h3>
            <p class="metric">{{ this.$t('DASHBOARD.TODAY.CONVERSATION') }}</p>
          </div>
          <div class="metric-content column">
            <h3 class="heading">{{ conversationByAgentCounter }}</h3>
            <p class="metric">
              {{ this.$t('DASHBOARD.TODAY.CONVERSATIONS_BY_ATTENDANT') }}
            </p>
          </div>
          <div class="metric-content column">
            <h3 class="heading emphasis">
              {{ accountSummary.resolutions_count }}
            </h3>
            <p class="metric">
              {{ this.$t('DASHBOARD.TODAY.COUNTER_RESOLUTION') }}
            </p>
          </div>
          <div class="divider" />
          <div class="metric-content column">
            <h3 class="heading">{{ mediumWaitingTime }}</h3>
            <p class="metric">
              {{ this.$t('DASHBOARD.TODAY.AVERAGE_WAITING_TIME') }}
            </p>
            <p class="metric">(TME)</p>
          </div>
          <div class="metric-content column">
            <h3 class="heading">{{ mediumTimeResponse }}</h3>
            <p class="metric">
              {{ this.$t('DASHBOARD.TODAY.MEDIUM_SERVICE_RESPONSE') }}
            </p>
            <p class="metric">(TMR)</p>
          </div>
          <div class="metric-content column">
            <h3 class="heading">{{ mediumTimeService }}</h3>
            <p class="metric">
              {{ this.$t('DASHBOARD.TODAY.MEDIUM_SERVICE_TIME') }}
            </p>
            <p class="metric">(TMA)</p>
          </div>
        </metric-card>
      </div>
      <div class="flex row">
        <metric-card-inbox-team
          key="inbox-reports"
          :header="this.$t('DASHBOARD.INBOX.TITLE')"
          :type="type"
          :getter-key="getterKey"
          :action-key="actionKey"
          :select-handler="handleInboxTeam"
        >
          <div class="metric-content column">
            <h3 class="heading">
              {{ accountInboxSummary.conversations_count }}
            </h3>
            <p class="metric">{{ this.$t('DASHBOARD.TODAY.CONVERSATION') }}</p>
          </div>
          <div class="metric-content column">
            <h3 class="heading">
              {{ conversationByAgentCounterInboxTeam }}
            </h3>
            <p class="metric">
              {{ this.$t('DASHBOARD.TODAY.CONVERSATIONS_BY_ATTENDANT') }}
            </p>
          </div>
          <div class="metric-content column">
            <h3 class="heading emphasis">
              {{ accountInboxSummary.resolutions_count }}
            </h3>
            <p class="metric">
              {{ this.$t('DASHBOARD.TODAY.COUNTER_RESOLUTION') }}
            </p>
          </div>
          <div class="divider" />

          <div class="metric-content column">
            <h3 class="heading">{{ mediumWaitingTimeInboxTeam }}</h3>
            <p class="metric">
              {{ this.$t('DASHBOARD.TODAY.AVERAGE_WAITING_TIME') }}
            </p>
            <p class="metric">(TME)</p>
          </div>
          <div class="metric-content column">
            <h3 class="heading">{{ mediumTimeResponseInboxTeam }}</h3>
            <p class="metric">
              {{ this.$t('DASHBOARD.TODAY.MEDIUM_SERVICE_RESPONSE') }}
            </p>
            <p class="metric">(TMR)</p>
          </div>

          <div class="metric-content column">
            <h3 class="heading">{{ mediumTimeServiceInboxTeam }}</h3>
            <p class="metric">
              {{ this.$t('DASHBOARD.TODAY.MEDIUM_SERVICE_TIME') }}
            </p>
            <p class="metric">(TMA)</p>
          </div>
        </metric-card-inbox-team>
      </div>

      <div class="flex row">
        <metric-card key="2" header="CSAT" class="csat">
          <csat-responses />
        </metric-card>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import MetricCard from './Components/MetricCard.vue';
import MetricCardInboxTeam from './Components/MetricCardInboxTeam.vue';
import alertMixin from 'shared/mixins/alertMixin';
import reportMixin from 'dashboard/mixins/reportMixin';
import timeMixin from 'dashboard/mixins/time';
import { getUnixStartOfDay, getUnixEndOfDay } from 'helpers/DateHelper';
import { formatTime } from '@chatwoot/utils';
import CsatResponses from '../settings/reports/CsatResponses.vue';
import { frontendURL } from '../../../helper/URLHelper';
import router from '../..';

export default {
  name: 'Home',
  components: { MetricCard, MetricCardInboxTeam, CsatResponses },
  mixins: [reportMixin, alertMixin, timeMixin],
  data() {
    return {
      isFullscreen: false,
      pageIndex: 1,
      getterKey: 'inboxes/getInboxes',
      actionKey: 'inboxes/get',
      type: 'inbox',
    };
  },
  computed: {
    ...mapGetters({
      agentStatus: 'agents/getAgentStatus',
      agents: 'agents/getAgents',
      accountConversationMetric: 'getAccountConversationMetric',
      accountSummary: 'getAccountSummary',
      accountInboxSummary: 'getAccountInboxSummary',
      currentUser: 'getCurrentUser',
      accountId: 'getCurrentAccountId',
    }),

    conversationByAgentCounter() {
      const result =
        this.accountSummary?.conversations_count / this.agents?.length;
      if (!isNaN(result)) {
        const formatted_number = result % 1 !== 0 ? result.toFixed(2) : result;
        return formatted_number;
      }
      return 0;
    },

    conversationByAgentCounterInboxTeam() {
      const result =
        this.accountInboxSummary?.conversations_count / this.agents?.length;
      if (!isNaN(result)) {
        const formatted_number = result % 1 !== 0 ? result.toFixed(2) : result;
        return formatted_number;
      }
      return 0;
    },

    mediumWaitingTime() {
      const temp = formatTime(this.accountSummary?.avg_first_response_time);
      return this.formatDate(temp);
    },

    mediumTimeService() {
      const temp = formatTime(this.accountSummary?.avg_resolution_time);
      return this.formatDate(temp);
    },

    mediumTimeServiceInboxTeam() {
      const temp = formatTime(this.accountInboxSummary?.avg_resolution_time);
      return this.formatDate(temp);
    },

    mediumTimeResponse() {
      const temp = formatTime(this.accountSummary?.avg_handling_time || 0);
      return this.formatDate(temp);
    },

    mediumTimeResponseInboxTeam() {
      const temp = formatTime(this.accountInboxSummary?.avg_handling_time || 0);
      return this.formatDate(temp);
    },

    mediumWaitingTimeInboxTeam() {
      const temp = formatTime(
        this.accountInboxSummary?.avg_first_response_time
      );
      return this.formatDate(temp);
    },
  },

  mounted() {
    this.have_permission();
    this.fetchData();
    this.$store.dispatch('fetchAccountConversationMetric', {
      type: 'account',
    });
    this.$store.dispatch('agents/get');

    this.$store.dispatch('fetchAgentConversationMetric', {
      type: 'agent',
      page: this.pageIndex,
    });
  },
  methods: {
    formatDate(temp) {
      const sec = this.$t('DASHBOARD.DATE.SECOND');
      const day = this.$t('DASHBOARD.DATE.DAY');
      const formattedTime = temp?.replace('Sec', sec).replace('Day', day);
      return formattedTime;
    },
    fetchData() {
      try {
        const payload = {
          businessHours: false,
          from: this.from(),
          to: this.to(),
          groupBy: 'day',
        };

        this.$store.dispatch('fetchAccountSummary', payload);
      } catch {
        this.showAlert(this.$t('REPORT.DATA_FETCHING_FAILED'));
      }
    },

    to() {
      return getUnixEndOfDay(new Date());
    },

    from() {
      return getUnixStartOfDay(new Date());
    },

    handleInboxTeam(selectedValue) {
      if (selectedValue === 'team') {
        this.type = 'team';
        this.getterKey = 'teams/getTeams';
        this.actionKey = 'teams/get';
      } else {
        this.type = 'inbox';
        this.getterKey = 'inboxes/getInboxes';
        this.actionKey = 'inboxes/get';
      }
    },

    toggleFullscreen() {
      this.isFullscreen = !this.isFullscreen;
      if (this.isFullscreen) {
        this.enterFullscreen();
      } else {
        this.exitFullscreen();
      }
    },
    enterFullscreen() {
      const fullscreenDiv = this.$refs.fullscreenDiv;
      if (fullscreenDiv.requestFullscreen) {
        fullscreenDiv.requestFullscreen();
      } else if (fullscreenDiv.mozRequestFullScreen) {
        fullscreenDiv.mozRequestFullScreen();
      } else if (fullscreenDiv.webkitRequestFullscreen) {
        fullscreenDiv.webkitRequestFullscreen();
      } else if (fullscreenDiv.msRequestFullscreen) {
        fullscreenDiv.msRequestFullscreen();
      }
    },
    exitFullscreen() {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    },
    have_permission() {
      if (!this.currentUser.permissions.dashboard && this.isSupervisor) {
        const path = frontendURL(`accounts/${this.accountId}/conversation`);
        router.push({ path });
      }
    },
  },
};
</script>

<style scoped>
.main {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.menu-header {
  background: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #f1f5f8;
  border-bottom: 1px solid var(--s-50);
  height: 5.6rem;
  min-height: 5.6rem;
  padding: 0.8rem 1.6rem;
}

.screen {
  text-align: center;
  height: 3.5rem;
  width: 3.5rem;
  font-size: 1.2rem;
  position: relative;
  background: #fff;
  border-radius: 50%;
  box-shadow: 0px 2px 3px rgba(4, 4, 7, 0.2);
  justify-content: center;
  cursor: pointer;
}
.screen img {
  width: 2rem;
  height: 2rem;
}

.icon {
  font-size: 17px;
  color: #1f2d3d;
}

.content-box {
  background: var(--s-25);
  width: 100%;
}

.flex {
  display: flex;
  width: 100%;
}

.card {
  display: flex;
  gap: 10px;
}

.cicle {
  height: 8px;
  width: 8px;
  border-radius: 50%;
}

.off {
  background: var(--s-500);
}

.on {
  background: var(--g-400);
}

.busy {
  background: var(--y-500);
}

.status {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.metric {
  font-weight: 500;
}

.red {
  color: rgb(240, 25, 41);
}
.emphasis {
  color: var(--g-20);
}
.divider {
  height: 60px;
  padding: 2px;
  background: var(--s-500);
}

.graphy h5 {
  margin-left: 15px;
}

.csat .content-box {
  background: transparent !important;
}

.btn-download-csat {
  display: none !important;
}
</style>
