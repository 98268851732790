<template>
  <modal :show.sync="show" :on-close="onClose" size="small">
    <div v-if="!uploadAction"  class="column content-box">
      <woot-modal-header :header-title="$t('IMPORT_CONTACTS.TITLE')">
        <p>
          {{ $t('IMPORT_CONTACTS.DESC')  + ' '}}
          <a class="btn-primary" :href="csvUrl" download="import-contacts-sample">CSV |</a>
          <a class="btn-primary" :href="xlsxUrl" download="import-contacts-sample">XLSX |</a>
          <a  class="btn-primary" :href="xlsUrl" download="import-contacts-sample">XLS</a>
        </p>
      </woot-modal-header>
      <div class="row modal-content">
        <div class="card-input-file">
          <span>{{ $t('IMPORT_CONTACTS.FORM.LABEL') + ' ' + 'CSV, XLSX, XLS' }}</span>
          <input
            ref="file"
            type="file"
            accept="text/csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"  
            @change="handleFileUpload"
            class="input-file"
          />
        </div>
        <div class="medium-12 columns">
          <label>
            <span>{{ $t('IMPORT_CONTACTS.WALLET.LABEL') }}</span>
          </label>
          <multiselect-dropdown
            :options="agentsWallets"
            :selected-item="agentSelectedWallet"
            :multiselector-title="$t('IMPORT_CONTACTS.WALLET.MULTISELECTOR')"
            :multiselector-placeholder="$t('AGENT_MGMT.MULTI_SELECTOR.PLACEHOLDER')"
            :no-search-result="$t('AGENT_MGMT.MULTI_SELECTOR.SEARCH.NO_RESULTS.AGENT')"
            :input-placeholder="$t('AGENT_MGMT.MULTI_SELECTOR.SEARCH.PLACEHOLDER.AGENT')"
            @click="getAgentWalletId"
          />
        </div>
        <div class="modal-footer">
          <div class="medium-12 columns">            
            <woot-button
              :disabled="uiFlags.isCreating || !file"
              :loading="uiFlags.isCreating"
              @click="uploadFile"
              class="bg-primary"
            >
              {{ $t('IMPORT_CONTACTS.FORM.SUBMIT') }}
            </woot-button>
            <button class="button clear btn-primary" @click.prevent="onClose">
              {{ $t('IMPORT_CONTACTS.FORM.CANCEL') }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div v-if="fileErrors.length" class="error-container medium-12 columns container-icons">
        <img :src="renderSvgAction" />
        <div class="container-message">
          <p class="error-title">{{ renderMessageAction }}</p>
          <ul class="error-list">
              <li 
                v-for="(error, index) in fileErrors" 
                :key="index" 
                class="error-item"
                >
                {{ error }}
              </li>
            </ul>
        </div>
      </div>
      <div class="container-icons error-content" v-else >
        <img :src="renderSvgAction" />
        <woot-modal-header :header-content="renderMessageAction" />
      </div>
    </div>
  </modal>
</template>

<script>
import Modal from '../../../../components/Modal';
import { mapGetters } from 'vuex';
import alertMixin from 'shared/mixins/alertMixin';
import { CONTACTS_EVENTS } from '../../../../helper/AnalyticsHelper/events';
import MultiselectDropdown from 'shared/components/ui/MultiselectDropdown.vue';
import XLSX from 'xlsx';
import Papa from 'papaparse';
import svgError from '../../../../../../../public/integrations/catalog/error-close-svgrepo-com.svg';
import svgSuccess from '../../../../../../../public/integrations/catalog/check-svgrepo-com.svg';
export default {
  components: {
    Modal,
    MultiselectDropdown,
  },
  mixins: [alertMixin],
  props: {
    onClose: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      errorUpload: false,
      uploadAction: false,
      show: true,
      file: '',
      page: 1,
      fileErrors: [],
      selectedWalletId: null,
      agentSelectedWallet: {
        confirmed: true,
        name: 'Nenhum',
        id: null,
        role: 'agent',
        account_id: 0,
        email: 'None',
        wallet: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      uiFlags: 'contacts/getUIFlags',
      agentList: 'agents/getAgents',
    }),
    renderSvgAction() {
      return this.fileErrors.length ? svgError : svgSuccess;
    },
    renderMessageAction() {
      return this.errorUpload 
        ? this.$t('IMPORT_CONTACTS.ERROR_UPLOAD.MESSAGE_IMPORT_FILE.ERROR') 
        : this.$t('IMPORT_CONTACTS.ERROR_UPLOAD.MESSAGE_IMPORT_FILE.SUCCESS');
    },
    agentsWallets() {
      if (this.agentList.length === 0)
        this.$store.dispatch('agents/get');
      
      const agents = this.agentList || [];
      return [
        ...([{
          confirmed: true,
          name: 'Nenhum',
          id: null,
          role: 'agent',
          account_id: 0,
          email: 'None',
          wallet: null,
        },]),
        ...agents,
      ];
    },
    csvUrl() {
      return '/downloads/import-contacts-csv-sample.csv';
    },
    xlsxUrl() {
      return '/downloads/import-contacts-xlsx-sample.xlsx';
    },
    xlsUrl() {
      return '/downloads/import-contacts-xls-sample.xls';
    },
  },
  mounted() {
    this.$track(CONTACTS_EVENTS.IMPORT_MODAL_OPEN);
  },
  methods: {
    async uploadFile() {
      this.uploadAction = true;
      try {
        if (!this.file || this.fileErrors.length > 0) return;
        await this.$store.dispatch('contacts/import', {
          file: this.file,
          selectedWalletId: this.selectedWalletId,
        });
        this.showAlert(this.$t('IMPORT_CONTACTS.SUCCESS_MESSAGE'));
        this.$track(CONTACTS_EVENTS.IMPORT_SUCCESS);
        this.errorUpload = false
      } catch (error) {
        this.showAlert(
          error.message || this.$t('IMPORT_CONTACTS.ERROR_MESSAGE')
        );
        this.errorUpload = true;
        this.$track(CONTACTS_EVENTS.IMPORT_FAILURE);
      }finally{
        this.$store.dispatch('contacts/getContactViews', {
            page: this.page
        });
      }
    },
    handleFileUpload(event) {
      this.fileErrors = [];
      
      const uploadedFile = event.target.files[0];
      if (!uploadedFile) return;

      this.file = uploadedFile;
      const fileExtension = uploadedFile.name.split('.').pop().toLowerCase();
      
      switch (fileExtension) {
        case 'xlsx':
        case 'xls':
          this.validateExcelFile(uploadedFile);
          break;
        case 'csv':
          this.validateCSVFile(uploadedFile);
          break;
        default:
          this.fileErrors.push(this.$t('IMPORT_CONTACTS.ERROR_UPLOAD.NOT_SUPPORT'));
      }
    },
    validateExcelFile(file) {
      const reader = new FileReader();
      
      reader.onload = (e) => {
        const workbook = XLSX.read(e.target.result, { type: 'binary' });
        const sheetName = workbook.SheetNames[0];
        const worksheet = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);
        
        this.validateData(worksheet);
      };
      
      reader.readAsBinaryString(file);
    },
    validateCSVFile(file) {
      Papa.parse(file, {
        header: true,
        complete: (results) => {
          this.validateData(results.data);
        },
        error: (error) => {
          this.fileErrors.push(`${this.$t('IMPORT_CONTACTS.ERROR_UPLOAD.NOT_SUPPORT_CSV')} ${error.message}`);
        }
      });
    },
    normalizePhoneNumber(phoneNumber) {
      const cleanedNumber = String(phoneNumber).replace(/\D/g, '');
      
      return cleanedNumber;
    },

    validateData(data) {
      this.fileErrors = [];

      if (data.length === 0) {
        this.fileErrors.push(this.$t('IMPORT_CONTACTS.ERROR_UPLOAD.EMPTY_FILE'));
        return;
      }

      data.forEach((row, index) => {
        if (!row.name) {
          this.fileErrors.push(`${this.$t('IMPORT_CONTACTS.ERROR_UPLOAD.LINE')}${index + 2}: ${this.$t('IMPORT_CONTACTS.ERROR_UPLOAD.ERROR_NAME')}`);
        }

        if (!row.phone_number) {
          this.fileErrors.push(`${this.$t('IMPORT_CONTACTS.ERROR_UPLOAD.LINE')} ${index + 2}: ${this.$t('IMPORT_CONTACTS.ERROR_UPLOAD.ERROR_PHONE')}`);
        }

        if (row.phone_number && !this.validatePhoneNumber(row.phone_number)) {
          this.fileErrors.push(`${this.$t('IMPORT_CONTACTS.ERROR_UPLOAD.LINE')} ${index + 2}: ${this.$t('IMPORT_CONTACTS.ERROR_UPLOAD.CORRECT_PHONE_FORMAT')}`);
        }

        if (row.phone_number) {
          row.phone_number = this.normalizePhoneNumber(row.phone_number);
        }
      });
      this.errorUpload = this.fileErrors.length > 0;
    },
    validatePhoneNumber(phoneNumber) {
      const cleanedNumber = String(phoneNumber).replace(/\D/g, '');

      const regex = /^(\d{2,4})(\d{2,3})(\d{8,9})$/;
      
      return regex.test(cleanedNumber);
    },
    getAgentWalletId(agent) {
      this.selectedWalletId = agent.id ? agent.id : null;
      this.agentSelectedWallet = agent;
    },
  },
};
</script>

<style scoped>
.error-container {
  margin-top: 10px;
  margin-bottom: 10px;
}

.error-title {
  color: red;
  font-weight: bold;
  margin-bottom: 5px;
  padding: 6px 33px;
}

.error-list {
  list-style-type: disc;
  padding-left: 20px;
}

.error-item {
  color: red;
  margin-bottom: 5px;
}
.content-box {
  overflow: unset;
}

.modal-footer {
  margin-top: 3rem;
}
.card-input-file {
  font-size: 1.4rem;
}
.card-input-file input {
  width: 80%;
}
.container-icons {
  font-weight: 600;

  img {
    display: flex;
    width: 15%;
    height: 15%;
    margin: 56px auto 0;
  }

  margin-bottom: 10px;
}

.container-message {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  align-content: center;
  align-items: center;
  margin: 22px 0;
}

.error-content {
  word-wrap: break-word;
  display: flex;
  margin-top: var(--space-small);
  justify-content: center;
  align-content: center;
  flex-direction: column;
  align-items: center;
}

</style>