<template>
  <header class="header">
    <div class="table-actions-wrap">
      <div class="left-aligned-wrap">
        <woot-sidemenu-icon />
        <h1 class="page-title header-title">
          {{ headerTitle }}
        </h1>
      </div>


      <div class="card-kanban">
        <div class="box-select-kanban">
          <kanban-context
            v-if="kanbanListActive.length > 0"
            :kanbanSelect="kanbanSelectItem"
            @toggle-kanban="toggleKanbanModal"
            :countKanban="kanbanListActive.length"
          />

        </div>
        <div class="card-data-tickets">
          <p class="total"> {{ meta.total_tickets }}&nbsp;</p>
          <p class="tickets" v-if="!isFunnelSale">{{ this.$t('TICKET.TITLE') }}</p>
          <p class="tickets" v-if="isFunnelSale">{{ this.$t('TICKET.TITLE') }}:&nbsp;</p>
          <p class="money" v-if="isFunnelSale">{{ formatCurrency(meta.total_sales_value) }}</p>
        </div>

      </div>

    </div>
    <woot-button
      v-if="showAddkanban"
      @click="$emit('redirect-kanban-page')"
      color-scheme="success"
      class-names="button--fixed-top mt-50 bg-primary"
      icon="add-circle"
    >
      {{ $t('CRM_MGMT.ADD.TITLE') }}
    </woot-button>

    <kanban-selector
      v-if="kanbanListActive.length > 0"
      :show-kanban-modal="showKanbanModal"
      @close-kanban-modal="toggleKanbanModal"
      @set-kanban="setKanban"
      :kanban-list="kanbanListActive"
      :kanban-select="kanbanSelectItem"
    />
  </header>
</template>

<script>
import { mapGetters } from 'vuex';
import adminMixin from 'dashboard/mixins/isAdmin';
import KanbanContext from './KanbanContext.vue';
import KanbanSelector from './KanbanSelector.vue';
import{ formatCurrency } from '../../../../helper/formatCurrency';

export default {
  mixins: [adminMixin],
  components: {
    KanbanContext,
    KanbanSelector
  },
  props: {
    headerTitle: {
      type: String,
      default: '',
    },
    meta: {
      type: Object,
      default: () => ({}),
    }
  },
  data(){
    return {
      kanbanSelected: null,
      showKanbanModal: false
    }
  },
  computed: {
    ...mapGetters({
      currentUser: 'getCurrentUser',
      accountId: 'getCurrentAccountId',
      kanbanList: 'getKanbanfunnels'
    }),

    isFunnelSale(){
      return this.kanbanSelectItem?.enable_sales_funnel;
    },


    showAddkanban(){
      if(this.isAdministrator){
        return true;
      }
      return this.currentUser?.permissions?.crm_settings
    },

    kanbanSelectItem(){
      if(!this.kanbanSelected) return null;
      return this.kanbanListActive.find(kanban => kanban.id == this.kanbanSelected)
    },

    kanbanListActive(){
      const listActive = this.kanbanList.filter(kanban => kanban.active);
      if(this.isAdministrator){
        this.kanbanSelected = !this.kanbanSelected  ? listActive[0]?.id : this.kanbanSelected;
        return listActive;
      }

      const kanbanWithPermission = listActive.filter(kanban => kanban?.agents_ids?.includes(this.currentUser.id));
      this.kanbanSelected = !this.kanbanSelected  ? kanbanWithPermission[0]?.id : this.kanbanSelected;
      return kanbanWithPermission;
    },
  },
  methods: {
    formatCurrency,

    emitKanbanSelected() {
      this.$emit('update-kanban-selected', this.kanbanSelected);
    },

    setKanban(id){
      this.kanbanSelected = id;
      this.toggleKanbanModal();
    },

    toggleKanbanModal() {
      this.showKanbanModal = !this.showKanbanModal;
    },

    openCreateAccountModal() {
      this.showKanbanModal = false;
    },
  },

  watch: {
    kanbanSelected(newVal) {
      if (newVal) {
        this.emitKanbanSelected();
      }
    }
  },
}
</script>

<style lang="scss" scoped>

.card-data-tickets {
  display: flex;
  align-items: center;
  .money, .total{
    font-weight: bold;
  }
  .money {
    color: #4CAF50;
  }

  p{
    margin: 0;
  }
}

.card-kanban {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: calc(95% - 175px);
  padding-right: 175px;
}

.table-actions-wrap {
  display: flex;
  gap: 3rem;
  width: 100%;
  min-height: 55px;
  padding: var(--space-small) var(--space-normal) var(--space-small)
    var(--space-normal);
}
.custom-select {
  border-radius: var(--border-radius-normal);
  border: 1px solid var(--color-border);
  font-size: var(--font-size-medium);
  margin: 0;
}

.box-select-kanban {
  display: flex;
  align-items: center;
  gap: 10px;
}
.box-select-kanban label {
    white-space: nowrap;
    font-size: 1.6rem;
    font-weight: bold;
  }
.page-title {
  margin: 0;
}

.left-aligned-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  min-width: var(--space-mega);

  .header-title {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    margin: 0 var(--space-small);
  }
}


</style>
