/* global axios */
import ApiClient from './ApiClient';

class AttributeAPI extends ApiClient {
  constructor() {
    super('custom_attribute_definitions', { accountScoped: true });
  }

  getAttributesByModel() {
    return axios.get(this.url);
  }
  getGroupAttributes() {
    return axios.get(`${this.baseUrl()}/group_attribute_tables`);
  }
  postGroupAttributes(data){
    return axios.post(`${this.baseUrl()}/group_attributes`, data)
  }
  deleteGroupAttributes(data){
    return axios.delete(`${this.baseUrl()}/group_attributes/${data}`)
  }
  deleteTable(data){
    return axios.delete(`${this.baseUrl()}/group_attribute_tables/${data}`)
  }
  updateGroupAttributes(id,data){
    return axios.put(`${this.baseUrl()}/group_attributes/${id}`,data)
  }
  postGroupAttributeTable(data){
    return axios.post(`${this.baseUrl()}/group_attribute_tables`, data)
  }
  updateGroupAttributesTable(id,data){
      return axios.put(`${this.baseUrl()}/group_attribute_tables/${id}`,data)
  }
}

export default new AttributeAPI();
