<template>
  <div class="contacts-page row">
    <div class="left-wrap medium-12">
      <kanban-header
        :header-title="pageTitle"
        :meta="kanbanMeta"
        @update-kanban-selected="handleKanbanSelected"
        @redirect-kanban-page="redirectKanbanPage"
      />
      <h3 v-if="loading" class="loading">{{ this.$t('CRM_MGMT.LOADING') }}</h3>
      <task-board v-if="!loading" :columns="columns" :kanban-id="kanbanId" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import KanbanHeader from './Header';
import TaskBoard from './Kanban/TaskBoard.vue';
import alertMixin from 'shared/mixins/alertMixin';
import router from '../../..';
import { frontendURL } from '../../../../helper/URLHelper';

export default {
  components: {
    KanbanHeader,
    TaskBoard,
  },
  mixins: [alertMixin],

  data() {
    return {
      columns: [],
      kanbanId: 0,
      loading: false,
      kanbanMeta: {},
    };
  },

  computed: {
    ...mapGetters({
      currentUser: 'getCurrentUser',
      accountId: 'getCurrentAccountId',
      kanbanList: 'getKanbanfunnels',
      currentAccountId: 'getCurrentAccountId',
      getAccount: 'accounts/getAccount',
      getTicketSalueValueChange: 'getTicketSalueValueChange',
    }),
    pageTitle() {
      return this.$t('CRM_PAGE.HEADER.TITLE');
    },

    userAssignmentKanban() {
      const account = this.getAccount(this.currentAccountId);

      if (!account?.enable_crm) {
        return false;
      }
      if (this.isAdministrator) {
        return true;
      }
      return this.kanbanList.some(
        kanban =>
          kanban?.agents_ids?.includes(this.currentUser.id) && kanban.active
      );
    },
  },

  watch: {
    getTicketSalueValueChange(newVal) {
      if (newVal) {
        const oldValue = Number(newVal.old) || 0;
        const newValue = Number(newVal.new) || 0;
        this.kanbanMeta.total_sales_value =
          Number(this.kanbanMeta?.total_sales_value) || 0;

        this.kanbanMeta.total_sales_value =
          this.kanbanMeta.total_sales_value - oldValue + newValue;

        const idColumn = newVal?.ticket?.columns_id;
        const targetColumn = this.columns?.find(
          col => col.column.id === idColumn
        );
        const targetTicket = targetColumn?.column?.tickets.find(
          ticket => ticket.id === newVal.ticket.id
        );

        if (targetColumn) {
          targetTicket.sale_value = newValue;
          targetColumn.column.meta.total_sales_value =
            Number(targetColumn.column.meta.total_sales_value || 0) -
            oldValue +
            newValue;
        }
      }
    },
  },
  mounted() {
    this.$store.dispatch('agents/get');
    this.validatePermission();
  },
  methods: {
    redirectKanbanPage() {
      const path = frontendURL(`accounts/${this.accountId}/settings/crm`);
      router.push({ path });
    },
    async validatePermission() {
      await this.$store.dispatch('accounts/get');
      await this.$store.dispatch('fetchKanbanfunnels');

      if (!this.userAssignmentKanban) {
        const path = frontendURL(`accounts/${this.accountId}/conversation`);
        router.push({ path });
      }
    },

    handleKanbanSelected(selectedKanban) {
      this.kanbanId = selectedKanban;
      this.getTicketsDefault(selectedKanban);
    },

    async getTicketsDefault(kanbanId) {
      const params = {
        kanbanfunnel_id: kanbanId,
        per_page: 10,
      };

      this.loading = true;
      const columsTickets = await this.$store.dispatch(
        'getTicketsByKanban',
        params
      );
      this.columns = columsTickets?.columns;
      this.kanbanMeta = columsTickets?.kanban_meta;
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.contacts-page {
  width: 100%;
}
.loading {
  text-align: center;
}
.left-wrap {
  display: flex;
  flex-direction: column;
  height: 100%;
}
</style>
