<template>
  <section class="contacts-table-wrap">
    <div>
      <label
        v-if="selectContactsLength > 0"
        class="bulk-action__panel flex-start"
      >
        <input
          type="checkbox"
          class="checkbox"
          :checked="allContactsSelected"
          :indeterminate.prop="!allContactsSelected"
          @change="handleSelectAll($event)"
        />
        <span>
          {{
            $t('BULK_ACTION.CONTACTS_SELECTED', {
              contactsCount: selectContactsLength,
            })
          }}
        </span>
      </label>
    </div>
    <div @mouseleave="onCardLeave">
      <ve-table
        :fixed-header="true"
        max-height="calc(100vh - 11.4rem)"
        scroll-width="187rem"
        :checkbox-option="checkboxOption"
        :columns="columns"
        :table-data="tableData"
        :border-around="false"
        :sort-option="sortOption"
        row-key-field-name="id"
      />
    </div>
    <empty-state
      v-if="showSearchEmptyState"
      :title="$t('CONTACTS_PAGE.LIST.404')"
    />
    <empty-state
      v-else-if="!isLoading && !contacts.length"
      :title="$t('CONTACTS_PAGE.LIST.NO_CONTACTS')"
    />
    <div v-if="isLoading" class="contacts--loader">
      <spinner />
      <span>{{ $t('CONTACTS_PAGE.LIST.LOADING_MESSAGE') }}</span>
    </div>

    <new-conversation
      v-if="showConversationModal"
      :show="showConversationModal"
      :contact-conversations="contactConversations"
      :contact="contactSelected"
      @success="closeConversationModal"
      @cancel="toggleConversationModal"
    />
  </section>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway';
import { VeTable } from 'vue-easytable';
import { getCountryFlag } from 'dashboard/helper/flag';

import Spinner from 'shared/components/Spinner.vue';
import Thumbnail from 'dashboard/components/widgets/Thumbnail.vue';
import EmptyState from 'dashboard/components/widgets/EmptyState.vue';
import timeMixin from 'dashboard/mixins/time';
import rtlMixin from 'shared/mixins/rtlMixin';
import FluentIcon from 'shared/components/FluentIcon/DashboardIcon';
import alertMixin from 'shared/mixins/alertMixin';
import NewConversation from '../../conversation/contact/NewConversation.vue';
import { mapGetters } from 'vuex';
import { frontendURL } from '../../../../helper/URLHelper';
import router from '../../..';
import ContainerCheckbox from './ContainerCheckbox.vue';
import agentMixin from '../../../../mixins/agentMixin';

export default {
  components: {
    EmptyState,
    Spinner,
    VeTable,
    NewConversation,
  },
  mixins: [alertMixin, clickaway, timeMixin, rtlMixin, agentMixin],
  props: {
    contacts: {
      type: Array,
      default: () => [],
    },
    showSearchEmptyState: {
      type: Boolean,
      default: false,
    },
    onClickContact: {
      type: Function,
      default: () => {},
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    activeContactId: {
      type: [String, Number],
      default: '',
    },
    sortParam: {
      type: String,
      default: 'last_activity_at',
    },
    sortOrder: {
      type: String,
      default: 'desc',
    },
    isContactsDelete: {
      type: Boolean,
      default: false,
    },
    selectContactsDelete: {
      type: Function,
      default: () => {},
    },
    selectedContacts: {
      type: Array,
      default: [],
    },
    selectAllContacts: {
      type: Function,
      default: () => {},
    },
    allContactsSelected: {
      type: Boolean,
      default: false,
    },
    selectContactsLength: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      checkboxOption: {
        selectedRowChange: ({ row, isSelected, selectedRowKeys }) => {
          this.showCheckBoxSelectAll = !isSelected;
          this.$emit('select-contacts-delete', selectedRowKeys);
        },
        selectedAllChange: ({ isSelected, selectedRowKeys }) => {
          this.showCheckBoxSelectAll = !isSelected;
          this.$emit('select-contacts-delete', selectedRowKeys);
        },
      },
      showCheckBoxSelectAll: true,
      sortConfig: {},
      sortOption: {
        sortAlways: true,
        sortChange: params => this.$emit('on-sort-change', params),
      },
      averageCellWidth: 240,
      isWhatsappInbox: false,
      contactSelected: null,
      showConversationModal: false,
      isChecked: false,
      checkAllContacts: false,
      checkedId: '',
      loadingNewConversation: {},
    };
  },
  computed: {
    ...mapGetters({
      accountId: 'getCurrentAccountId',
      activeInbox: 'getSelectedInbox',
      currentUserID: 'getCurrentUserID',
      inboxes: 'inboxes/getInboxes',
      currentUser: 'getCurrentUser',
    }),
    verifyContacts() {
      return this.isContactsDelete;
    },
    contactConversations() {
      const currentId = this.currentUser?.id;
      const currentConversations = this.$store.getters[
        'contactConversations/getContactConversation'
      ](
        this.contactId,
        currentId,
        this.isLimitedAgent,
        !this.isPermissionSeeAllTeamConversation
      );
      return currentConversations;
    },
    openConversations() {
      if (this.activeInbox) {
        return this.contactConversations.filter(
          conversation =>
            conversation.inbox_id === this.activeInbox &&
            conversation?.meta?.channel != 'Channel::Internal' &&
            conversation.status !== 'resolved'
        );
      }

      return this.contactConversations.filter(
        conversation => conversation.status !== 'resolved'
      );
    },
    tableData() {
      if (this.isLoading) {
        return [];
      }
      return this.contacts
        .map(item => {
          // Note: The attributes used here is in snake case
          // as it simplier the sort attribute calculation
          const additional = item?.additional_attributes || {};
          const { last_activity_at: lastActivityAt } = item;
          const { created_at: createdAt } = item;
          const contact_type_formate = item.contact_type
            ? item.contact_type.toUpperCase()
            : 'STANDARD';
          const contact_type = this.$t(
            `CONTACTS_FILTER.CONTACT_TYPES.${contact_type_formate}`
          );
          return {
            ...item,
            isStandard: true,
            whatsappValid: item.whatsapp_valid,
            phone_number: item.phone_number || '---',
            contact_type: contact_type || '---',
            company: additional.company_name || '---',
            profiles: additional.social_profiles || {},
            city: additional.city || '---',
            country: additional.country,
            countryCode: additional.country_code,
            conversationsCount: item.conversations_count || '---',
            last_activity_at: lastActivityAt
              ? this.dynamicTime(lastActivityAt)
              : '---',
            created_at: createdAt ? this.dynamicTime(createdAt) : '---',
          };
        })
        .filter(contact => !contact.phone_number.includes('@g.us'));
    },
    columns() {
      return [
        {
          field: 'name',
          key: 'name',
          title: this.$t('CONTACTS_PAGE.LIST.TABLE_HEADER.NAME'),
          display: 'flex',
          property: 'name',
          rowKey: ({ row }) => row.id,
          fixed: 'left',
          align: this.isRTLView ? 'right' : 'left',
          sortBy: this.sortConfig.name || '',
          width: this.averageCellWidth,
          renderBodyCell: ({ row }) => (
            <ContainerCheckbox
              mouseenter={() => this.onCardHove(row.id)}
              mouseleave={() => this.onCardLeave()}
            >
              <div
                onClick={() => this.onClickContact(row.id)}
                class="row--user-block"
              >
                {this.checkedId === row.id ? (
                  <label class="checkbox-wrapper">
                    <input
                      value={this.isSelected(row.id)}
                      checked={this.isSelected(row.id)}
                      class="checkbox"
                      type="checkbox"
                      onChange={() => this.handleSelected(row.id)}
                    />
                  </label>
                ) : (
                  <Thumbnail
                    src={row.thumbnail}
                    size="32px"
                    username={row.name}
                    status={row.availability_status}
                  />
                )}
                <div class="user-block">
                  <h6 class="sub-block-title text-truncate">
                    <router-link
                      to={`/app/accounts/${this.$route.params.accountId}/contacts/${row.id}`}
                      class="user-name"
                    >
                      {row.name}
                    </router-link>
                  </h6>
                  <button class="button clear small link view-details--button">
                    {this.$t('CONTACTS_PAGE.LIST.VIEW_DETAILS')}
                  </button>
                </div>
              </div>
            </ContainerCheckbox>
          ),
        },
        {
          field: 'email',
          key: 'email',
          title: this.$t('CONTACTS_PAGE.LIST.TABLE_HEADER.EMAIL_ADDRESS'),
          align: this.isRTLView ? 'right' : 'left',
          sortBy: this.sortConfig.email || '',
          width: this.averageCellWidth,
          renderBodyCell: ({ row }) => {
            if (row.email)
              return (
                <div class="text-truncate user-email-white">
                  <a
                    target="_blank"
                    rel="noopener noreferrer nofollow"
                    href={`mailto:${row.email}`}
                    class="user-email-white"
                    style="white-space: normal; overflow-wrap: break-word;"
                  >
                    {row.email}
                  </a>
                </div>
              );
            return '---';
          },
        },
        {
          field: 'phone_number',
          key: 'phone_number',
          sortBy: this.sortConfig.phone_number || '',
          title: this.$t('CONTACTS_PAGE.LIST.TABLE_HEADER.PHONE_NUMBER'),
          align: this.isRTLView ? 'right' : 'left',
          width: this.averageCellWidth,
        },
        {
          field: 'company',
          key: 'company',
          sortBy: this.sortConfig.company_name || '',
          title: this.$t('CONTACTS_PAGE.LIST.TABLE_HEADER.COMPANY'),
          align: this.isRTLView ? 'right' : 'left',
        },
        {
          field: 'city',
          key: 'city',
          sortBy: this.sortConfig.city || '',
          title: this.$t('CONTACTS_PAGE.LIST.TABLE_HEADER.CITY'),
          align: this.isRTLView ? 'right' : 'left',
        },
        {
          field: 'country',
          key: 'country',
          title: this.$t('CONTACTS_PAGE.LIST.TABLE_HEADER.COUNTRY'),
          align: this.isRTLView ? 'right' : 'left',
          sortBy: this.sortConfig.country || '',
          renderBodyCell: ({ row }) => {
            if (row.country) {
              return (
                <div class="text-truncate">
                  {`${getCountryFlag(row.countryCode)} ${row.country}`}
                </div>
              );
            }
            return '---';
          },
        },
        {
          field: 'profiles',
          key: 'profiles',
          title: this.$t('CONTACTS_PAGE.LIST.TABLE_HEADER.SOCIAL_PROFILES'),
          align: this.isRTLView ? 'right' : 'left',
          renderBodyCell: ({ row }) => {
            const { profiles } = row;

            const items = Object.keys(profiles);

            if (!items.length) return '---';

            return (
              <div class="cell--social-profiles">
                {items.map(
                  profile =>
                    profiles[profile] && (
                      <a
                        target="_blank"
                        rel="noopener noreferrer nofollow"
                        href={`https://${profile}.com/${profiles[profile]}`}
                      >
                        <FluentIcon icon={`brand-${profile}`} />
                      </a>
                    )
                )}
              </div>
            );
          },
        },
        {
          field: 'last_activity_at',
          key: 'last_activity_at',
          sortBy: this.sortConfig?.last_activity_at || '',
          title: this.$t('CONTACTS_PAGE.LIST.TABLE_HEADER.LAST_ACTIVITY'),
          align: this.isRTLView ? 'right' : 'left',
          width: this.averageCellWidth,
        },
        {
          field: 'created_at',
          key: 'created_at',
          sortBy: this.sortConfig.created_at || '',
          title: this.$t('CONTACTS_PAGE.LIST.TABLE_HEADER.CREATED_AT'),
          align: this.isRTLView ? 'right' : 'left',
        },
        {
          field: 'conversationsCount',
          key: 'conversationsCount',
          title: this.$t('CONTACTS_PAGE.LIST.TABLE_HEADER.CONVERSATIONS'),
          width: 150,
          align: this.isRTLView ? 'right' : 'left',
        },
        {
          field: 'contact_type',
          key: 'contact_type',
          sortBy: this.sortConfig.contact_type || '',
          title: this.$t('CONTACTS_FILTER.CONTACT_TYPES.TYPE'),
          align: this.isRTLView ? 'right' : 'left',
          width: 90,
        },
        {
          field: 'actions',
          key: 'actions',
          fixed: 'right',
          width: 90,
          align: 'center',
          renderBodyCell: ({ row }) => (
            <div>
              {row.isStandard && (
                <woot-button
                  onClick={() => this.onClickNewChat(row)}
                  class="new-message bg-primary"
                  isLoading={this.loadingNewConversation[row?.id] || false}
                  icon="chat"
                  size="small"
                />
              )}
            </div>
          ),
        },
      ];
    },
  },
  watch: {
    sortOrder() {
      this.setSortConfig();
    },
    sortParam() {
      this.setSortConfig();
    },
  },
  mounted() {
    if (this.$route.name.includes('contact')) {
      this.$store.dispatch('setActiveInbox', null);
    }
    this.setSortConfig();
    this.verifyWhatsappInbox();
  },
  methods: {
    handleSelected(id) {
      return this.$emit('select-contacts', id);
    },
    onCardHove(id) {
      this.checkedId = id;
      this.isChecked = true;
    },
    onCardLeave() {
      if (this.verifyContacts) {
        this.checkedId = '';
        this.isChecked = false;
      }
    },
    isSelected(id) {
      return this.selectedContacts.includes(id);
    },
    handleSelectAll(e) {
      this.$emit('select-all-contacts', e);
    },
    setSortConfig() {
      this.sortConfig = { [this.sortParam]: this.sortOrder };
    },

    verifyWhatsappInbox() {
      this.inboxes?.find(inbox => {
        if (
          inbox.id == this.activeInbox &&
          (inbox.channel_type === 'Channel::Whatsapp' ||
            inbox.channel_type === 'Channel::Api')
        ) {
          this.isWhatsappInbox = true;
        }
      });
    },

    verifyInbox() {
      const inboxSelected = this.inboxes?.find(inbox => {
        return inbox.id == this.activeInbox;
      });

      if (
        !inboxSelected &&
        this.inboxes.length == 1 &&
        this.inboxes[0].channel_type !== 'Channel::Whatsapp'
      ) {
        return this.inboxes[0];
      }

      return inboxSelected;
    },

    async onClickNewChat(contact) {
      try {
        this.$set(this.loadingNewConversation, contact.id, true);
        this.contactId = contact?.id;
        await this.$store.dispatch(
          'contacts/fetchContactableInbox',
          contact?.id
        );
        const contactState = this.$store.getters['contacts/getContact'](
          contact.id
        );
        this.contactSelected = { ...contact, ...contactState };
        await this.fetchData(contact);
        const isInboxNotOfficial = this.verifyInbox();
        if (isInboxNotOfficial) {
          if (this.openConversations.length > 0) {
            this.showAlert(this.$t('CONTACT_PANEL.ALREADY_OPEN_CONVERSATION'));
            return;
          }

          const payload = {
            assigneeId: this.currentUserID,
            contactId: contact.id,
            inboxId: isInboxNotOfficial.id,
            accountId: this.accountId,
          };

          const data = await this.$store.dispatch(
            'contactConversations/create',
            payload
          );

          const path = frontendURL(
            `accounts/${this.accountId}/inbox/${isInboxNotOfficial.id}/conversations/${data.id}`
          );
          router.push({ path });
          return;
        }
      } catch {
        this.showAlert(this.$t('CONTACT_PANEL.ERROR_OPEN_NEW_CONVERSATION'));
      } finally {
        this.$set(this.loadingNewConversation, contact.id, false);
      }
      if (this.activeInbox) {
        if (this.openConversations.length > 0) {
          this.showAlert(this.$t('CONTACT_PANEL.ALREADY_OPEN_CONVERSATION'));
          return;
        }
      }
      this.showConversationModal = true;
    },

    async fetchData(contact) {
      await this.$store.dispatch('contactConversations/get', contact?.id);
    },

    closeConversationModal() {
      this.$emit('close-modal-contact');
      this.toggleConversationModal();
    },
    toggleConversationModal() {
      this.showConversationModal = !this.showConversationModal;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~dashboard/assets/scss/mixins';

.contacts-table-wrap {
  flex: 1 1;
  height: 100%;
  overflow: hidden;
}

.flex {
  display: flex;
  align-items: center;
  gap: 2rem;
}

.text-truncate a {
  color: var(--g-20);
}

.contacts-table-wrap::v-deep {
  .ve-table {
    padding-bottom: var(--space-large);
  }
  .new-message {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2.2rem !important;
    width: 2.2rem !important;
  }
  .row--user-block {
    align-items: center;
    display: flex;
    text-align: left;
    white-space: nowrap;

    .user-block {
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      margin: 0 var(--space-small);
    }

    .user-name {
      font-size: var(--font-size-small);
      font-weight: var(--font-weight-medium);
      margin: 0;
      text-transform: capitalize;
      white-space: normal;
    }

    .view-details--button {
      color: var(--color-body);
    }

    .user-email {
      margin: 0;
    }

    .user-email-white {
      white-space: normal;
      overflow-wrap: break-word;
    }
  }

  .ve-table-header-th {
    padding: var(--space-small) var(--space-two) !important;
  }

  .ve-table-body-td {
    padding: var(--space-small) var(--space-two) !important;
  }
  td label.ve-checkbox {
    position: relative !important;
    z-index: 1 !important;
    left: 7% !important;
    top: 50% !important;
    white-space: nowrap !important;
  }
  .ve-table-body-td:first-child {
    height: 80px !important;
  }

  .ve-checkbox {
    font-size: 15px;
  }

  .hidden-label-parent
    .ve-table-header-th.ve-table-fixed-left.ve-table-last-left-fixed-column
    label {
    display: none !important;
  }

  .ve-checkbox .ve-checkbox-indeterminate .ve-checkbox-inner:after {
    top: 50% !important;
    left: 50% !important;
    width: 8px !important;
    height: 8px !important;
    background-color: #108ee9;
    border: 0;
    transform: translate(-50%, -50%) scale(1);
    opacity: 1;
    content: ' ';
  }

  .bulk-action__panel {
    cursor: pointer;

    span {
      font-size: var(--font-size-mini);
      margin: 0 var(--space-smaller);
    }

    input[type='checkbox'] {
      cursor: pointer;
      margin: var(--space-zero);
    }
  }

  span.ve-checkbox-content span.ve-checkbox-inner {
    width: 13px;
    height: 13px;
  }

  tr.ve-checkbox-inner:after {
    width: 13px;
    height: 13px;
  }
  .ve-checkbox-content.ve-checkbox-checked {
    width: 13px;
    height: 13px;
  }

  .ve-checkbox-content .ve-checkbox-inner:after {
    left: 3px;
    top: 0px;
    width: 6px;
    height: 9px;
  }

  .ve-table-body-tr:last-child {
    height: 80px !important;
  }

  .ve-table-header-th {
    font-size: var(--font-size-mini) !important;
  }
  .ve-table-sort {
    top: -4px;
  }
}

.contacts--loader {
  align-items: center;
  display: flex;
  font-size: var(--font-size-default);
  justify-content: center;
  padding: var(--space-big);
}

.cell--social-profiles {
  a {
    color: var(--s-300);
    display: inline-block;
    font-size: var(--font-size-medium);
    min-width: var(--space-large);
    text-align: center;
  }
}

.ve-table *,
.ve-table *:before,
.ve-table *:after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  text-overflow: ellipsis;
  overflow: hidden !important;
}

.checkbox-wrapper {
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 auto;
  border-radius: 100%;
  margin-top: 0px;
  cursor: pointer;

  &:hover {
    background-color: var(--w-100);
  }

  input[type='checkbox'] {
    margin: 0px !important;
    cursor: pointer;
  }
}
</style>
