import axios from 'axios';
const {
    TRANSCRIPTION_URL: transcriptionUrl,
} = window.globalConfig || {};

const instance = axios.create({
  baseURL: transcriptionUrl,
  headers: {
    'Content-Type': 'application/json',
  },
});

export default instance;
