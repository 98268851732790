<template>
  <div class="contacts-page row">
    <div class="left-wrap" :class="wrapClass">
      <contacts-header
        :search-query="searchQuery"
        :segments-id="segmentsId"
        :on-search-submit="onSearchSubmit"
        :on-export-submit="onExportSubmit"
        this-selected-contact-id=""
        :on-input-search="onInputSearch"
        :on-toggle-create="onToggleCreate"
        :on-toggle-import="onToggleImport"
        :on-toggle-delete-accounts="onToggleDeleteAccounts"
        :is-contacts-delete="isContactsDelete"
        :on-toggle-filter="onToggleFilters"
        :header-title="pageTitle"
        @on-toggle-save-filter="onToggleSaveFilters"
        @on-toggle-delete-filter="onToggleDeleteFilters"
        @on-toggle-edit-filter="onToggleFilters"
      />
      <contacts-table
        :contacts="recordsView"
        :show-search-empty-state="showEmptySearchResult"
        :is-loading="uiFlags.isFetching"
        :is-contacts-delete="isContactsDelete"
        :on-click-contact="openContactInfoPanel"
        :active-contact-id="selectedContactId"
        :selected-contacts="selectedContacts"
        :select-contacts-length="selectContactsLength"
        :all-contacts-selected="allContactsSelected"
        @on-sort-change="onSortChange"
        @close-modal-contact="closeModalContact"
        @select-contacts="selectContacts"
        @select-all-contacts="selectAllContacts"
      />
      <table-footer
        :current-page="Number(meta.currentPage)"
        :total-count="meta.count"
        :page-size="15"
        @page-change="onPageChange"
      />
    </div>

    <add-custom-views
      v-if="showAddSegmentsModal"
      :custom-views-query="segmentsQuery"
      :filter-type="filterType"
      :open-last-saved-item="openSavedItemInSegment"
      @close="onCloseAddSegmentsModal"
    />
    <delete-custom-views
      v-if="showDeleteSegmentsModal"
      :show-delete-popup.sync="showDeleteSegmentsModal"
      :active-custom-view="activeSegment"
      :custom-views-id="segmentsId"
      :active-filter-type="filterType"
      :open-last-item-after-delete="openLastItemAfterDeleteInSegment"
      @close="onCloseDeleteSegmentsModal"
    />

    <contact-info-panel
      v-if="showContactViewPane"
      :contact="selectedContact"
      :on-close="closeContactInfoPanel"
      :conversation-id="conversationId"
      :on-send="onSend"
      :inbox="inbox"
      :message="message"
      :toggle-contact="toggleContact"
      @toggle-contacts-view="toggleContactsView"
    />
    <woot-delete-modal
      :show.sync="showDeleteModal"
      :on-close="onToggleDeleteAccounts"
      :on-confirm="deleteContactsConfirm"
      :title="$t('AGENT_MGMT.DELETE.CONFIRM.TITLE')"
      :message="$t('AGENT_MGMT.DELETE.CONFIRM.MESSAGE')"
      :message-value="deleteMessage"
      :confirm-text="deleteConfirmText"
      :reject-text="deleteRejectText"
    />

    <create-contact :show="showCreateModal" @cancel="onToggleCreate" />
    <woot-modal :show.sync="showImportModal" :on-close="onToggleImport">
      <import-contacts v-if="showImportModal" :on-close="onToggleImport" />
    </woot-modal>
    <woot-modal
      :show.sync="showFiltersModal"
      :on-close="closeAdvanceFiltersModal"
      size="medium"
    >
      <contacts-advanced-filters
        v-if="showFiltersModal"
        :on-close="closeAdvanceFiltersModal"
        :initial-filter-types="contactFilterItems"
        :initial-applied-filters="appliedFilter"
        :active-segment-name="activeSegmentName"
        :is-segments-view="hasActiveSegments"
        @applyFilter="onApplyFilter"
        @updateSegment="onUpdateSegment"
        @clearFilters="clearFilters"
      />
    </woot-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import ContactsHeader from './Header';
import ContactsTable from './ContactsTable';
import ContactInfoPanel from './ContactInfoPanel';
import CreateContact from 'dashboard/routes/dashboard/conversation/contact/CreateContact';
import TableFooter from 'dashboard/components/widgets/TableFooter';
import ImportContacts from './ImportContacts.vue';
import ContactsAdvancedFilters from './ContactsAdvancedFilters.vue';
import contactFilterItems from '../contactFilterItems';
import filterQueryGenerator from '../../../../helper/filterQueryGenerator';
import AddCustomViews from 'dashboard/routes/dashboard/customviews/AddCustomViews';
import DeleteCustomViews from 'dashboard/routes/dashboard/customviews/DeleteCustomViews';
import { CONTACTS_EVENTS } from '../../../../helper/AnalyticsHelper/events';
import alertMixin from 'shared/mixins/alertMixin';
import isAdmin from '../../../../mixins/isAdmin';
import countries from 'shared/constants/countries.js';
import { generateValuesForEditCustomViews } from 'dashboard/helper/customViewsHelper';
import { frontendURL } from '../../../../helper/URLHelper';
import router from '../../..';

const DEFAULT_PAGE = 1;
const FILTER_TYPE_CONTACT = 1;

export default {
  components: {
    ContactsHeader,
    ContactsTable,
    TableFooter,
    ContactInfoPanel,
    CreateContact,
    ImportContacts,
    ContactsAdvancedFilters,
    AddCustomViews,
    DeleteCustomViews,
  },
  mixins: [alertMixin, isAdmin],
  props: {
    label: { type: String, default: '' },
    segmentsId: {
      type: [String, Number],
      default: 0,
    },
    conversationId: {
      type: Number,
      default: undefined,
    },
    onSend: {
      type: Function,
      default: () => {},
    },
    inbox: {
      type: Object,
      default: () => ({}),
    },
    message: {
      type: String,
      default: '',
    },
    toggleContact: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      searchQuery: '',
      showCreateModal: false,
      showImportModal: false,
      selectedContacts: [],
      selectContactsByName: [],
      showDeleteModal: false,
      selectedContactId: '',
      sortConfig: { last_activity_at: 'desc' },
      showFiltersModal: false,
      contactFilterItems: contactFilterItems.map(filter => ({
        ...filter,
        attributeName: this.$t(
          `CONTACTS_FILTER.ATTRIBUTES.${filter.attributeI18nKey}`
        ),
      })),
      segmentsQuery: {},
      filterType: FILTER_TYPE_CONTACT,
      showAddSegmentsModal: false,
      showDeleteSegmentsModal: false,
      appliedFilter: [],
    };
  },
  computed: {
    ...mapGetters({
      recordsView: 'contacts/getContactsViews',
      uiFlags: 'contacts/getUIFlags',
      meta: 'contacts/getMeta',
      segments: 'customViews/getCustomViews',
      getAppliedContactFilters: 'contacts/getAppliedContactFilters',
      currentUser: 'getCurrentUser',
      accountId: 'getCurrentAccountId',
      statusFilterConversationAndContact: 'getStatusFilter',
      activeInbox: 'getSelectedInbox',
      inboxes: 'inboxes/getInboxes',
    }),
    allContactsSelected() {
      return (
        this.recordsView.length === this.selectedContacts.length &&
        this.recordsView.every(el => this.selectedContacts.includes(el.id))
      );
    },
    selectContactsLength() {
      return this.selectedContacts.length;
    },
    deleteConfirmText() {
      return `${this.$t('AGENT_MGMT.DELETE.CONFIRM.YES')} `;
    },
    deleteRejectText() {
      return `${this.$t('AGENT_MGMT.DELETE.CONFIRM.NO')} `;
    },
    deleteMessage() {
      return `nome ? ${this.selectContactsByName}`;
    },
    isContactsDelete() {
      return this.selectedContacts.length <= 0;
    },
    currentRouteName() {
      return this.$route.name;
    },
    isWalletContacts() {
      return this.$route.name === 'wallet_contacts_dashboard';
    },
    filteredContactsByAgentOrAdmin() {
      return this.recordsView.filter(contact => {
        return (
          contact.wallet?.id === this.currentUserWallet.id ||
          !contact.wallet?.id ||
          this.isAdmin
        );
      });
    },
    showEmptySearchResult() {
      const hasEmptyResults =
        !!this.searchQuery && this.recordsView.length === 0;
      return hasEmptyResults;
    },
    hasAppliedFilters() {
      return this.getAppliedContactFilters.length;
    },
    hasActiveSegments() {
      return this.activeSegment && this.segmentsId !== 0;
    },
    isContactAndLabelDashboard() {
      const routes = [
        'home',
        'inbox_dashboard',
        'inbox_conversation',
        'conversation_mentions',
        'label_conversations',
        'contacts_dashboard',
        'wallet_contacts_dashboard',
        'contacts_labels_dashboard',
        'conversation_through_inbox',
      ];
      return !!routes.includes(this.$route.name);
    },
    pageTitle() {
      if (this.isWalletContacts) {
        return this.$t('CONVERSATION_SIDEBAR.ACCORDION.WALLET_CONTACTS');
      }
      if (this.hasActiveSegments) {
        return this.activeSegment.name;
      }
      if (this.label) {
        return `#${this.label}`;
      }
      return this.$t('CONTACTS_PAGE.HEADER');
    },
    selectedContact() {
      if (this.selectedContactId) {
        const contact = this.recordsView.find(
          item => this.selectedContactId === item.id
        );
        return contact;
      }
      return undefined;
    },
    showContactViewPane() {
      return this.selectedContactId !== '';
    },
    wrapClass() {
      return this.showContactViewPane ? 'medium-9' : 'medium-12';
    },
    pageParameter() {
      const selectedPageNumber = Number(this.$route.query?.page);
      return !Number.isNaN(selectedPageNumber) &&
        selectedPageNumber >= DEFAULT_PAGE
        ? selectedPageNumber
        : DEFAULT_PAGE;
    },
    activeSegment() {
      if (this.segmentsId) {
        const [firstValue] = this.segments.filter(
          view => view.id === Number(this.segmentsId)
        );
        return firstValue;
      }
      return undefined;
    },
    activeSegmentName() {
      return this.activeSegment?.name;
    },
  },
  watch: {
    recordsView(value) {
      this.returnPage(value);
    },
    selectedContacts(value) {
      const namesContact = value
        .map(id => {
          const item = this.recordsView?.find(obj => obj?.id === id);
          return item ? item?.name : null;
        })
        ?.filter(name => name !== null)
        .join(', ');

      this.selectContactsByName = namesContact;
    },
    label() {
      this.fetchContacts(DEFAULT_PAGE);
      if (this.hasAppliedFilters) {
        this.clearFilters();
      }
    },
    currentRouteName() {
      this.fetchContacts(DEFAULT_PAGE);
      if (this.hasAppliedFilters) {
        this.clearFilters();
      }
    },
    activeSegment() {
      if (this.hasActiveSegments) {
        const payload = this.activeSegment.query;
        this.fetchSavedFilteredContact(payload, DEFAULT_PAGE);
      }
      if (this.hasAppliedFilters && this.$route.name === 'contacts_dashboard') {
        this.fetchFilteredContacts(DEFAULT_PAGE);
      } else {
        this.fetchContacts(DEFAULT_PAGE);
      }
    },

    statusFilterConversationAndContact: {
      handler(newValue) {
        if (newValue == 2) {
          this.onToggleFilters();
        }
      },
      immediate: true,
    },
  },
  mounted() {
    if (this.$route.name.includes('contact')) {
      this.$store.dispatch('setActiveInbox', null);
    }
    this.have_permission();
    this.fetchContacts(this.pageParameter);
  },
  methods: {
    returnPage(value) {
      if (
        !value.length &&
        !this.uiFlags.isFetching &&
        this.meta.currentPage > 1
      ) {
        const path = frontendURL(
          `accounts/${this.accountId}/contacts?page=${this.meta.currentPage -
            1}`
        );
        this.$router.push({ path });
      }
    },
    isContactsSelected(id) {
      return this.selectedContacts.includes(id);
    },
    selectContacts(conversationId) {
      if (!this.isContactsSelected(conversationId)) {
        this.selectedContacts.push(conversationId);
      } else {
        this.deSelectContacts(conversationId);
      }
    },
    deSelectContacts(conversationId) {
      this.selectedContacts = this.selectedContacts.filter(
        item => item !== conversationId
      );
    },
    selectAllContacts(e) {
      const allSelected = this.recordsView.every(item =>
        this.selectedContacts.includes(item?.id)
      );

      if (e.target.checked && !allSelected) {
        this.recordsView.forEach(item => {
          if (!this.selectedContacts.includes(item?.id)) {
            this.selectedContacts.push(item?.id);
          }
        });
      } else if (e.target.checked) {
        this.selectedContacts = this.recordsView.map(item => item?.id);
      } else {
        this.selectedContacts = this.selectedContacts.filter(
          id => !this.recordsView.some(contact => contact.id === id)
        );
      }
    },

    closeModalContact() {
      this.$emit('toggle-contacts-view');
    },
    async deleteContactsConfirm() {
      try {
        const requestParams = { contacts: this.selectedContacts };

        this.$store.dispatch('contacts/deleteMultipleContacts', requestParams);
        this.resetContactsDelete();
        this.onToggleDeleteAccounts();
      } catch (error) {
        console.log(error);
      }
    },
    resetContactsDelete() {
      this.selectedContacts = [];
      this.selectContactsByName = [];
    },
    have_permission() {
      if (!this.currentUser.permissions.contacts && this.isSupervisor) {
        const path = frontendURL(`accounts/${this.accountId}/conversation`);
        router.push({ path });
      }
    },
    updatePageParam(page) {
      window.history.pushState({}, null, `${this.$route.path}?page=${page}`);
    },
    getSortAttribute() {
      let sortAttr = Object.keys(this.sortConfig).reduce((acc, sortKey) => {
        const sortOrder = this.sortConfig[sortKey];
        if (sortOrder) {
          const sortOrderSign = sortOrder === 'asc' ? '' : '-';
          return `${sortOrderSign}${sortKey}`;
        }
        return acc;
      }, '');
      if (!sortAttr) {
        this.sortConfig = { last_activity_at: 'desc' };
        sortAttr = '-last_activity_at';
      }
      return sortAttr;
    },
    fetchContacts(page) {
      if (this.isContactAndLabelDashboard) {
        this.updatePageParam(page);
        let searchQuery = '';
        if (this.searchQuery.charAt(0) === '+') {
          searchQuery = this.searchQuery.substring(1);
        } else {
          searchQuery = this.searchQuery;
        }
        const requestParams = {
          page,
          sortAttr: this.getSortAttribute(),
          label: this.label,
          wallet_contacts: this.isWalletContacts,
        };

        const selectedInbox = this.inboxes?.find(
          inbox => inbox.id === this.activeInbox
        );
        if (searchQuery) {
          this.$store.dispatch('contacts/searchContactViews', {
            search: encodeURIComponent(searchQuery),
            ...requestParams,
            inbox: selectedInbox,
          });
        } else {
          this.$store.dispatch('contacts/getContactViews', {
            ...requestParams,
            inbox: selectedInbox,
          });
        }
      }
    },
    fetchSavedFilteredContact(payload, page) {
      if (this.hasActiveSegments) {
        this.updatePageParam(page);
        this.$store.dispatch('contacts/filterContactViews', {
          queryPayload: payload,
          page,
        });
      }
    },
    fetchFilteredContacts(page) {
      if (this.hasAppliedFilters) {
        const payload = this.segmentsQuery;
        this.updatePageParam(page);
        this.$store.dispatch('contacts/filterContactViews', {
          queryPayload: payload,
          page,
        });
      }
    },

    onInputSearch(event) {
      const newQuery = event.target.value;
      const refetchAllContacts = !!this.searchQuery && newQuery === '';
      this.searchQuery = newQuery;
      if (refetchAllContacts) {
        this.fetchContacts(DEFAULT_PAGE);
      }
    },
    onSearchSubmit() {
      this.selectedContactId = '';
      if (this.searchQuery) {
        this.fetchContacts(DEFAULT_PAGE);
      }
    },
    onPageChange(page) {
      this.selectedContactId = '';
      if (this.segmentsId !== 0) {
        const payload = this.activeSegment.query;
        this.fetchSavedFilteredContact(payload, page);
      }
      if (this.hasAppliedFilters) {
        this.fetchFilteredContacts(page);
      } else {
        this.fetchContacts(page);
      }
    },
    openContactInfoPanel(contactId) {
      this.selectedContactId = contactId;
      this.showContactInfoPanelPane = true;
    },
    closeContactInfoPanel() {
      this.selectedContactId = '';
      this.showContactInfoPanelPane = false;
    },
    toggleContactsView() {
      this.$emit('toggle-contacts-view');
    },
    onToggleCreate() {
      this.showCreateModal = !this.showCreateModal;
    },
    onToggleSaveFilters() {
      this.showAddSegmentsModal = true;
    },
    onCloseAddSegmentsModal() {
      this.showAddSegmentsModal = false;
    },
    onToggleDeleteFilters() {
      this.showDeleteSegmentsModal = true;
    },
    onCloseDeleteSegmentsModal() {
      this.showDeleteSegmentsModal = false;
    },
    onToggleImport() {
      this.showImportModal = !this.showImportModal;
    },
    onToggleDeleteAccounts() {
      this.showDeleteModal = !this.showDeleteModal;
      this.closeContactInfoPanel();
    },
    onSortChange(params) {
      this.sortConfig = params;
      this.fetchContacts(this.meta.currentPage);

      const sortBy =
        Object.entries(params).find(pair => Boolean(pair[1])) || [];

      this.$track(CONTACTS_EVENTS.APPLY_SORT, {
        appliedOn: sortBy[0],
        order: sortBy[1],
      });
    },
    onToggleFilters() {
      if (this.hasActiveSegments) {
        this.initializeSegmentToFilterModal(this.activeSegment);
      }
      this.showFiltersModal = true;
    },
    closeAdvanceFiltersModal() {
      this.showFiltersModal = false;
      this.appliedFilter = [];
      this.setStatusFilter(0);
    },
    onApplyFilter(payload) {
      this.setStatusFilter(0);
      this.closeContactInfoPanel();
      this.segmentsQuery = filterQueryGenerator(payload);
      this.$store.dispatch('contacts/filterContactViews', {
        queryPayload: filterQueryGenerator(payload),
      });
      this.showFiltersModal = false;
    },
    setStatusFilter(status) {
      this.$store.dispatch('setStatusFilterConversationEndContact', { status });
    },
    onUpdateSegment(payload, segmentName) {
      const payloadData = {
        ...this.activeSegment,
        name: segmentName,
        query: filterQueryGenerator(payload),
      };
      this.$store.dispatch('customViews/update', payloadData);
      this.closeAdvanceFiltersModal();
    },
    clearFilters() {
      this.$store.dispatch('contacts/clearContactFilters');
      this.fetchContacts(this.pageParameter);
    },
    onExportSubmit() {
      try {
        this.$store.dispatch('contacts/export');
        this.showAlert(this.$t('EXPORT_CONTACTS.SUCCESS_MESSAGE'));
      } catch (error) {
        this.showAlert(
          error.message || this.$t('EXPORT_CONTACTS.ERROR_MESSAGE')
        );
      }
    },
    setParamsForEditSegmentModal() {
      // Here we are setting the params for edit segment modal to show the existing values.

      // For custom attributes we get only attribute key.
      // So we are mapping it to find the input type of the attribute to show in the edit segment modal.
      const params = {
        countries: countries,
        filterTypes: contactFilterItems,
        allCustomAttributes: this.$store.getters[
          'attributes/getAttributesByModel'
        ]('contact_attribute'),
      };
      return params;
    },
    initializeSegmentToFilterModal(activeSegment) {
      // Here we are setting the params for edit segment modal.
      //  To show the existing values. when we click on edit segment button.

      // Here we get the query from the active segment.
      // And we are mapping the query to the actual values.
      // To show in the edit segment modal by the help of generateValuesForEditCustomViews helper.
      const query = activeSegment?.query?.payload;
      if (!Array.isArray(query)) return;

      this.appliedFilter.push(
        ...query.map(filter => ({
          attribute_key: filter.attribute_key,
          attribute_model: filter.attribute_model,
          filter_operator: filter.filter_operator,
          values: Array.isArray(filter.values)
            ? generateValuesForEditCustomViews(
                filter,
                this.setParamsForEditSegmentModal()
              )
            : [],
          query_operator: filter.query_operator,
          custom_attribute_type: filter.custom_attribute_type,
        }))
      );
    },
    openSavedItemInSegment() {
      const lastItemInSegments = this.segments[this.segments.length - 1];
      const lastItemId = lastItemInSegments.id;
      this.$router.push({
        name: 'contacts_segments_dashboard',
        params: { id: lastItemId },
      });
    },
    openLastItemAfterDeleteInSegment() {
      if (this.segments.length > 0) {
        this.openSavedItemInSegment();
      } else {
        this.$router.push({ name: 'contacts_dashboard' });
        this.fetchContacts(DEFAULT_PAGE);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.contacts-page {
  width: 100%;
}

.left-wrap {
  display: flex;
  flex-direction: column;
  height: 100%;
}
</style>
