<template>
  <woot-modal :show.sync="show" :on-close="onClose">
    <div class="column content-box">
      <woot-modal-header
        v-if="!errorCode"
        :header-title="$t('ATTRIBUTES_MGMT.ADD.TITLE')"
      />
      <div v-if="errorCode">
        <div
          :class="{
            'container-icons': true,
          }"
        >
          <img :src="renderSvgAction" />
          <p class="return-message">{{ renderMessageAction }}</p>
        </div>
      </div>
      <div v-else>
        <div v-if="indexTab !== 2">
          <form class="row" @submit.prevent="addAttributes">
            <div v-if="indexTab !== 2" class="medium-12 columns">
              <label :class="{ error: $v.attributeModel.$error }">
                {{ $t('ATTRIBUTES_MGMT.ADD.FORM.MODEL.LABEL') }}
                <select v-model="attributeModel">
                  <option
                    v-for="model in models"
                    :key="model.id"
                    :value="model.id"
                  >
                    {{ model.option }}
                  </option>
                </select>
                <span v-if="$v.attributeModel.$error" class="message">
                  {{ $t('ATTRIBUTES_MGMT.ADD.FORM.MODEL.ERROR') }}
                </span>
              </label>
              <woot-input
                v-model="displayName"
                :label="$t('ATTRIBUTES_MGMT.ADD.FORM.NAME.LABEL')"
                type="text"
                :class="{ error: $v.displayName.$error }"
                :error="
                  $v.displayName.$error
                    ? $t('ATTRIBUTES_MGMT.ADD.FORM.NAME.ERROR')
                    : ''
                "
                :placeholder="$t('ATTRIBUTES_MGMT.ADD.FORM.NAME.PLACEHOLDER')"
                @input="onDisplayNameChange"
                @blur="$v.displayName.$touch"
              />
              <woot-input
                v-model="attributeKey"
                :label="$t('ATTRIBUTES_MGMT.ADD.FORM.KEY.LABEL')"
                type="text"
                :class="{ error: $v.attributeKey.$error }"
                :error="$v.attributeKey.$error ? keyErrorMessage : ''"
                :placeholder="$t('ATTRIBUTES_MGMT.ADD.FORM.KEY.PLACEHOLDER')"
                @blur="$v.attributeKey.$touch"
              />
              <label :class="{ error: $v.description.$error }">
                {{ $t('ATTRIBUTES_MGMT.ADD.FORM.DESC.LABEL') }}
                <textarea
                  v-model="description"
                  rows="3"
                  type="text"
                  :placeholder="$t('ATTRIBUTES_MGMT.ADD.FORM.DESC.PLACEHOLDER')"
                  @blur="$v.description.$touch"
                />
                <span v-if="$v.description.$error" class="message">
                  {{ $t('ATTRIBUTES_MGMT.ADD.FORM.DESC.ERROR') }}
                </span>
              </label>
              <label :class="{ error: $v.attributeType.$error }">
                {{ $t('ATTRIBUTES_MGMT.ADD.FORM.TYPE.LABEL') }}
                <select v-model="attributeType">
                  <option v-for="type in types" :key="type.id" :value="type.id">
                    {{ type.option }}
                  </option>
                </select>
                <span v-if="$v.attributeType.$error" class="message">
                  {{ $t('ATTRIBUTES_MGMT.ADD.FORM.TYPE.ERROR') }}
                </span>
              </label>
              <div v-if="isAttributeTypeList" class="multiselect--wrap">
                <label>
                  {{ $t('ATTRIBUTES_MGMT.ADD.FORM.TYPE.LIST.LABEL') }}
                </label>
                <multiselect
                  ref="tagInput"
                  v-model="values"
                  :placeholder="
                    $t('ATTRIBUTES_MGMT.ADD.FORM.TYPE.LIST.PLACEHOLDER')
                  "
                  label="name"
                  track-by="name"
                  :class="{ invalid: isMultiselectInvalid }"
                  :options="options"
                  :multiple="true"
                  :taggable="true"
                  @close="onTouch"
                  @tag="addTagValue"
                />
                <label v-show="isMultiselectInvalid" class="error-message">
                  {{ $t('ATTRIBUTES_MGMT.ADD.FORM.TYPE.LIST.ERROR') }}
                </label>
              </div>
              <div class="modal-footer">
                <woot-submit-button
                  class="bg-primary"
                  :disabled="isButtonDisabled"
                  :button-text="$t('ATTRIBUTES_MGMT.ADD.SUBMIT')"
                />
                <button class="button clear" @click.prevent="onClose">
                  {{ $t('ATTRIBUTES_MGMT.ADD.CANCEL_BUTTON_TEXT') }}
                </button>
              </div>
            </div>
          </form>
        </div>
        <div v-else-if="groupAttributes.length">
          <div class="medium-12 columns">
            <div class="attributes-upload-tabs">
              <woot-tabs :index="selectedTabIndex" @change="onClickTabChange">
                <woot-tabs-item
                  v-for="tab in tabs"
                  :key="tab.key"
                  :name="tab.name"
                  :show-badge="false"
                />
              </woot-tabs>
            </div>

            <div v-if="selectedTabIndex == 1" class="main-form">
              <div>
                <span class="span-label">
                  <form @submit.prevent="addCustomAttribute">
                    <woot-input
                      v-model.trim="additionalAttributes.company"
                      :label="groupAttributes[0].company.name"
                      type="text"
                      :placeholder="groupAttributes[0].company.name"
                    />
                    <woot-input
                      v-model.trim="additionalAttributes.client_code"
                      :label="groupAttributes[0].client_code.name"
                      type="text"
                      :placeholder="groupAttributes[0].client_code.name"
                    />
                    <woot-input
                      v-model.trim="additionalAttributes.status"
                      :label="groupAttributes[0].status.name"
                      type="text"
                      :placeholder="groupAttributes[0].status.name"
                    />
                    <woot-input
                      v-model.trim="additionalAttributes.monthly_fee"
                      :label="groupAttributes[0].monthly_fee.name"
                      type="text"
                      :placeholder="groupAttributes[0].monthly_fee.name"
                    />
                    <woot-input
                      v-if="groupAttributes[0].custom_field_first.name"
                      v-model.trim="additionalAttributes.custom_field_first"
                      :label="groupAttributes[0].custom_field_first.name"
                      type="text"
                      :placeholder="groupAttributes[0].custom_field_first.name"
                    />
                    <woot-input
                      v-if="groupAttributes[0].custom_field_second.name"
                      v-model.trim="additionalAttributes.custom_field_second"
                      :label="groupAttributes[0].custom_field_second.name"
                      type="text"
                      :placeholder="groupAttributes[0].custom_field_second.name"
                    />
                    <div class="modal-footer">
                      <woot-button :disabled="isGroupButtonDisabled">
                        {{ $t('CUSTOM_ATTRIBUTES.FORM.CREATE') }}
                      </woot-button>
                      <woot-button variant="clear" @click.prevent="onClose">
                        {{ $t('CUSTOM_ATTRIBUTES.FORM.CANCEL') }}
                      </woot-button>
                    </div>
                  </form>
                </span>
              </div>
            </div>
            <div v-else class="file-upload">
              <p class="sheet-model">
                <a
                  class="btn-primary"
                  :href="xlsxUrl"
                  download="customAttributes.xlsx"
                  >{{ $t('ATTRIBUTES_MGMT.DOWNLOAD_SHEET') }}</a
                >
              </p>
              <div class="spreadsheet-container">
                <label for="file-input" class="upload-button">
                  <i class="icon-upload" />
                  {{ $t('ATTRIBUTES_MGMT.ADD.SPREADSHEET') }}
                </label>
                <input
                  id="file-input"
                  ref="fileInput"
                  accept=".xlsx, .xml"
                  type="file"
                  @change="handleFileUpload"
                />
                <span class="file-name">{{
                  fileName || 'Nenhuma lista foi selecionada.'
                }}</span>
              </div>
              <div class="modal-footer">
                <button
                  class="button primary"
                  :disabled="!fileData"
                  @click="processFile"
                >
                  {{ $t('ATTRIBUTES_MGMT.ADD.PROCESS_SHEET') }}
                </button>
                <button class="button clear" @click="resetFile">
                  {{ $t('ATTRIBUTES_MGMT.ADD.CLEAN') }}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <add-table @on-close="onClose" />
        </div>
      </div>
    </div>
  </woot-modal>
</template>

<script>
import { required, minLength } from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';
import { convertToAttributeSlug } from 'dashboard/helper/commons.js';
import { ATTRIBUTE_MODELS, ATTRIBUTE_TYPES } from './constants';
import alertMixin from 'shared/mixins/alertMixin';
import * as XLSX from 'xlsx';
import AddTable from './AddTable.vue';
import svgError from '../../../../../../../public/integrations/catalog/error-close-svgrepo-com.svg';
import svgSucess from '../../../../../../../public/integrations/catalog/check-svgrepo-com.svg';

export default {
  components: {
    AddTable,
  },
  mixins: [alertMixin],
  props: {
    onClose: {
      type: Function,
      default: () => {},
    },
  },

  data() {
    return {
      displayName: '',
      description: '',
      attributeModel: 0,
      attributeType: 0,
      attributeKey: '',
      models: ATTRIBUTE_MODELS,
      types: ATTRIBUTE_TYPES,
      values: [],
      options: [],
      show: true,
      isTouched: false,
      selectedTabIndex: 0,
      fileName: null,
      fileData: null,
      errorCode: '',
      additionalAttributes: {
        company: '',
        client_code: '',
        status: '',
        monthly_fee: '',
        custom_field_first: '',
        custom_field_second: '',
      },
    };
  },

  mounted() {
    console.log('souza box1', this.indexTab);
  },

  computed: {
    ...mapGetters({
      uiFlags: 'getUIFlags',
      indexTab: 'attributes/getIndexTabAttributes',
    }),
    groupAttributes() {
      const customAttribute = this.$store.getters[
        'attributes/getAttributesByModel'
      ]('group_attribute');
      return customAttribute;
    },
    xlsxUrl() {
      return '/downloads/customAttributes.xlsx';
    },
    renderSvgAction() {
      return this.errorCode == 'success' ? svgSucess : svgError;
    },
    renderMessageAction() {
      if (this.errorCode == 'error') {
        return this.$t('ATTRIBUTES_MGMT.ADD.SHEET_ERROR');
      }

      return this.$t('ATTRIBUTES_MGMT.ADD.SHEET_SUCCESS');
    },
    tabs() {
      return [
        {
          key: 0,
          name: 'Upload de Planilha',
        },
        {
          key: 1,
          name: 'Registro Manual',
        },
      ];
    },
    isMultiselectInvalid() {
      return this.isTouched && this.values.length === 0;
    },
    isTagInputInvalid() {
      return this.isAttributeTypeList && this.values.length === 0;
    },
    attributeListValues() {
      return this.values.map(item => item.name);
    },
    isButtonDisabled() {
      return (
        this.$v.displayName.$invalid ||
        this.$v.description.$invalid ||
        this.uiFlags.isCreating ||
        this.isTagInputInvalid
      );
    },
    isGroupButtonDisabled() {
      return (
        this.$v.additionalAttributes.company.$invalid ||
        this.$v.additionalAttributes.status.$invalid ||
        this.$v.additionalAttributes.monthly_fee.$invalid ||
        this.$v.additionalAttributes.client_code.$invalid
      );
    },
    keyErrorMessage() {
      if (!this.$v.attributeKey.isKey) {
        return this.$t('ATTRIBUTES_MGMT.ADD.FORM.KEY.IN_VALID');
      }
      return this.$t('ATTRIBUTES_MGMT.ADD.FORM.KEY.ERROR');
    },
    isAttributeTypeList() {
      return this.attributeType === 6;
    },
  },

  validations: {
    displayName: {
      required,
      minLength: minLength(1),
    },
    additionalAttributes: {
      company: {
        required,
      },
      client_code: {
        required,
      },
      status: {
        required,
      },
      monthly_fee: {
        required,
      },
    },
    description: {
      required,
    },
    attributeModel: {
      required,
    },
    attributeType: {
      required,
    },
    attributeKey: {
      required,
      isKey(value) {
        return !(value.indexOf(' ') >= 0);
      },
    },
  },

  methods: {
    handleFileChange(event) {
      const file = event.target.files[0];
      this.fileName = file ? file.name : null;
    },

    addTagValue(tagValue) {
      const tag = {
        name: tagValue,
      };
      this.values.push(tag);
      this.$refs.tagInput.$el.focus();
    },
    onClickTabChange(index) {
      this.selectedTabIndex = index;
    },
    isAdditionalAttributesInvalid() {
      const { additionalAttributes, ...rest } = this.$v;
      return rest;
    },
    handleFileUpload(event) {
      const file = event.target.files[0];
      this.fileName = file ? file.name : null;

      if (!file) {
        this.fileData = null;
        return;
      }

      const reader = new FileReader();
      reader.onload = e => {
        this.fileData = e.target.result;
      };
      reader.readAsArrayBuffer(file);
    },

    async processFile() {
      if (!this.fileData) {
        this.showAlert(this.$t('ATTRIBUTES_MGMT.ADD.FILE_NOT_UPLOADED'));
        return;
      }

      try {
        const data = new Uint8Array(this.fileData);
        const workbook = XLSX.read(data, { type: 'array' });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const rows = XLSX.utils.sheet_to_json(sheet);

        // eslint-disable-next-line no-restricted-syntax
        for (const row of rows) {
          // eslint-disable-next-line no-await-in-loop
          await this.addAttributesFromRow(row);
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    },

    async addAttributesFromRow(row) {
      try {
        await this.$store.dispatch('attributes/create', {
          attributeType: 'group_attribute',
          company: row.first_field,
          client_code: row.second_field,
          status: row.third_field,
          monthly_fee: row.fourth_field,
          custom_field_first: row.custom_field_first || '',
          custom_field_second: row.custom_field_second || '',
          table_id: this.groupAttributes[0]?.id || null,
          name: this.groupAttributes[0]?.name || '',
          name_id: convertToAttributeSlug(row.first_field),
        });
        this.errorCode = 'success';
      } catch (error) {
        this.errorCode = 'error';
        throw error;
      }
    },
    async addCustomAttribute() {
      try {
        await this.$store.dispatch('attributes/create', {
          attributeType: 'group_attribute',
          company: this.additionalAttributes?.company || '',
          client_code: this.additionalAttributes?.client_code || '',
          status: this.additionalAttributes.status || '',
          monthly_fee: this.additionalAttributes.monthly_fee || '',
          custom_field_first:
            this.additionalAttributes.custom_field_first || '',
          custom_field_second:
            this.additionalAttributes.custom_field_second || '',
          table_id: this.groupAttributes[0]?.id || null,
          name: this.groupAttributes[0]?.name || '',
          name_id: convertToAttributeSlug(this.additionalAttributes?.company),
        });
        this.alertMessage = this.$t('ATTRIBUTES_MGMT.ADD.API.SUCCESS_MESSAGE');
        this.errorCode = 'success';
        // eslint-disable-next-line no-empty
      } catch (error) {}
    },

    resetFile() {
      this.fileName = null;
      this.fileData = null;
      this.$refs.fileInput.value = null;
    },

    onTouch() {
      this.isTouched = true;
    },
    onDisplayNameChange() {
      this.attributeKey = convertToAttributeSlug(this.displayName);
    },
    async addAttributes() {
      this.$v.$touch();
      if (this.$v.$invalid && !this.isAdditionalAttributesInvalid()) {
        return;
      }
      try {
        await this.$store.dispatch('attributes/create', {
          attribute_display_name: this.displayName,
          attribute_description: this.description,
          attribute_model: this.attributeModel,
          attribute_display_type: this.attributeType,
          attribute_key: this.attributeKey,
          attribute_values: this.attributeListValues,
        });
        this.alertMessage = this.$t('ATTRIBUTES_MGMT.ADD.API.SUCCESS_MESSAGE');
        this.onClose();
      } catch (error) {
        const errorMessage = error?.message;
        this.alertMessage =
          errorMessage || this.$t('ATTRIBUTES_MGMT.ADD.API.ERROR_MESSAGE');
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.key-value {
  padding: 0 var(--space-small) var(--space-small) 0;
  font-family: monospace;
}
.attributes-upload-tabs {
  margin: 20px 15px 0px 15px;
}

.multiselect--wrap {
  margin-bottom: var(--space-normal);

  .error-message {
    color: var(--r-400);
    font-size: var(--font-size-small);
    font-weight: var(--font-weight-normal);
  }

  .invalid {
    ::v-deep {
      .multiselect__tags {
        border: 1px solid var(--r-400);
      }
    }
  }
}

::v-deep {
  .multiselect {
    margin-bottom: 0;
  }

  .multiselect__content-wrapper {
    display: none;
  }

  .multiselect--active .multiselect__tags {
    border-radius: var(--border-radius-normal);
  }

  .tabs {
    padding: 0;
  }
}

.return-message {
  margin: 0px 0px;
}

.container-icons {
  min-height: 400px;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;

  img {
    display: flex;
    width: 15%;
    height: 15%;
  }
}

.error-not-space {
  color: red;
}

.sheet-model {
  margin-bottom: 15px;
}

.main-form {
  margin-top: 1rem;
}

.file-upload {
  margin: 20px 15px 0px 15px;
  display: flex;
  flex-direction: column;
}

.upload-button {
  background-color: #00d3f7;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  font-size: 14px;
  display: flex;
  align-items: center;
}

.upload-button:hover {
  background-color: #00a5c4;
}

.upload-button .icon-upload {
  margin-right: 8px;
}

.action-buttons {
  margin-top: 15px;
}

input[type='file'] {
  display: none;
}

.spreadsheet-container {
  display: flex;
  align-items: center;
}

.file-name {
  margin-left: 10px;
  color: #a0a0a0;
  font-size: 14px;
}

.span-label {
  display: block;
  margin: 0;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1.8;
  color: #3c4858;
}

.main-form {
  margin-top: 1rem;
}

.file-upload {
  display: flex;
  align-items: center;
}

.upload-button {
  background-color: #00d3f7;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  font-size: 14px;
  display: flex;
  align-items: center;
}

.upload-button:hover {
  background-color: #00a5c4;
}

.upload-button .icon-upload {
  margin-right: 8px;
}

input[type='file'] {
  display: none;
}

.file-name {
  margin-left: 10px;
  color: #a0a0a0;
  font-size: 14px;
}

.span-label {
  display: block;
  margin: 0;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1.8;
  color: #3c4858;
}
</style>
