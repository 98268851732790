<template>
    <woot-modal :show.sync="show" :on-close="onClose" size="modal-fit">
        <woot-button color-scheme="secondary" icon="dismiss" variant="clear" class="media-close"
            @click="$emit('cancel')" />
        <img v-if="fileType == 'image'" class="image-preview" :src="src" />
        <div v-else>
            <vue-pdf-embed v-for="page in 4" :key="page" :source="src" :width="500" :scale="1"
                :page="page" />
        </div>
    </woot-modal>
</template>

<script>
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed';
export default {
    components: { VuePdfEmbed},
    name: 'ModalMedia',
    props: {
        fileType: {
            type: String,
            default: ''
        },
        src: {
            type: String,
            default: ''
        },
        show: {
            type: Boolean,
            default: true,
        },
    },
    computed: {
    },
    methods: {
        onClose() {
            this.$emit('cancel');
        },
    },
};
</script>

<style scoped>
.modal-content {
    padding: 2.5rem 3.2rem;
}

.media-close {
    position: absolute;
    right: var(--space-small);
    top: var(--space-small);
    z-index: 9999;
}

.image-preview {
    width: 500px !important;
}
</style>
