<template>
  <div class="csat--table-container">
    <ve-table max-height="calc(100vh - 35rem)" :fixed-header="true" :columns="columns" :table-data="tableData" />
    <div v-show="!tableData.length" class="csat--empty-records">
      {{ $t('CSAT_REPORTS.NO_RANKING')}}
    </div>
  </div>
</template>

<script>
import { VeTable } from 'vue-easytable';
import UserAvatarWithName from 'dashboard/components/widgets/UserAvatarWithName';
import { mapGetters } from 'vuex';
import timeMixin from 'dashboard/mixins/time';
import rtlMixin from 'shared/mixins/rtlMixin';
import Thophy01 from '../../../../../../../../../public/trophy/trophy-01.svg';
import Thophy02 from '../../../../../../../../../public/trophy/trophy-02.svg';

import Thophy03 from '../../../../../../../../../public/trophy/trophy-03.svg';


export default {
  components: {
    VeTable
  },
  mixins: [timeMixin, rtlMixin],
  computed: {
    ...mapGetters({
      uiFlags: 'csat/getUIFlags',
      csatResponses: 'csat/getCSATResponses',
      metrics: 'csat/getMetrics',
    }),
    columns() {
      return [
        {
          field: 'assignedAgent',
          key: 'assignedAgent',
          title: this.$t('CSAT_REPORTS.TABLE.HEADER.RANKING_CSAT_AGENT_NAME'),
          align: this.isRTLView ? 'right' : 'left',
          width: 150,
          renderBodyCell: ({ row, rowIndex }) => {
            let positionLabel = this.renderTrophyIcon(rowIndex);
          
            if (row?.assigned_agent_name) {
              return (
                <div class="assigned-agent-cell">
                  {positionLabel}
                  <div>{row.assigned_agent_name}</div>
                </div>
              );
            }
            return '---';
          },
        },
        {
          field: 'responsesCount',
          key: 'responsesCount',
          title: this.$t('CSAT_REPORTS.TABLE.HEADER.RANKING_CSAT_SCORE'),
          align: 'center',
          width: 150,
          renderBodyCell: ({ row }) => {
            return row.rating_count || '---';
          },
        },
        {
          field: 'percentage',
          key: 'percentage',
          title: this.$t('CSAT_REPORTS.TABLE.HEADER.RANKING_CSAT_PERCENT'),
          align: 'center',
          width: 150,
          renderBodyCell: ({ row }) => {
            return row.percentage?.toFixed(2) ? `${row.percentage?.toFixed(2)}%` : '---';
          },
        },
      ];
    },
    tableData() {
    return this.metrics?.rankingCsat
  }
  },
  methods: {
    renderTrophyIcon(rowIndex){
      let result = ''
      if (rowIndex === 0) {
              result =
                <div class="container--icon">
                   <img  src={Thophy01} /> 
                </div>
            } else if (rowIndex === 1) {
              result =
                <div class="container--icon">
                  <img  src={Thophy02} /> 

                </div>
            } else if (rowIndex === 2) {
              result =
                <div class="container--icon">
                  <img  src={Thophy03} />
                </div>
            } else {
              result =
                <div class="container--icon">
                  --
                </div>
            }
            return result;
    },

    analyzeRatings(data) {
      const totalRatingsOverall = data.length;

      const agentCounts = {};

      data.forEach(item => {
        const agentId = item.assigned_agent.id;
        const agentName = item.assigned_agent.name;

        if (!agentCounts[agentId]) {
          agentCounts[agentId] = {
            name: agentName,
            totalRatings: 0,
            rating5Count: 0,
          };
        }

        agentCounts[agentId].totalRatings++;
        if (item.rating === 5) {
          agentCounts[agentId].rating5Count++;
        }
      });

      const agentResults = Object.entries(agentCounts).map(
        ([agentId, counts]) => {
          const agentPercentage =
            (counts.totalRatings / totalRatingsOverall) * 100;

          return {
            agentName: counts.name,
            totalRatings: counts.totalRatings,
            percentage: Number(agentPercentage.toFixed(2)),
          };
        }
      );

      const sortedResults = agentResults.sort(
        (a, b) => b.totalRatings - a.totalRatings
      );

      return {
        overall: {
          totalRatings: totalRatingsOverall,
        },
        byAgent: sortedResults,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.csat--empty-records {
  align-items: center;
  background-color: var(--white);
  border: 1px solid var(--color-border);
  border-top: 0;
  color: var(--b-600);
  display: flex;
  font-size: var(--font-size-small);
  height: 20rem;
  justify-content: center;
  margin-top: -1px;
  width: 100%;
}
.container--icon {
  width: 15px;
  height: 15px;
  margin-right: 15px;
}

.assigned-agent-cell {
    display: flex;
    align-items: center;
}
</style>
