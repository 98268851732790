import { render, staticRenderFns } from "./TrophyIcon.vue?vue&type=template&id=43aac734&scoped=true&"
import script from "./TrophyIcon.vue?vue&type=script&lang=js&"
export * from "./TrophyIcon.vue?vue&type=script&lang=js&"
import style0 from "./TrophyIcon.vue?vue&type=style&index=0&id=43aac734&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "43aac734",
  null
  
)

export default component.exports