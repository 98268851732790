<template>
  <modal :show.sync="show" :on-close="onClose">
    <div class="column content-box">
      <woot-modal-header :header-title="pageTitle" :info-button="$t('CANNED_MGMT.HINT')"/>
      <form class="row medium-8" @submit.prevent="editCannedResponse()">
        <div >
          <suggestion-buttons :suggestions="suggestions" @customVariable="customVariable"/>
        </div>
        <div class="medium-12 columns">
          <label :class="{ error: $v.shortCode.$error }">
            {{ $t('CANNED_MGMT.EDIT.FORM.SHORT_CODE.LABEL') }}
            <input
              v-model.trim="shortCode"
              type="text"
              :placeholder="$t('CANNED_MGMT.EDIT.FORM.SHORT_CODE.PLACEHOLDER')"
              @input="$v.shortCode.$touch"
            />
          </label>
        </div>

        <div class="medium-12 columns">
          <label :class="{ error: $v.content.$error }">
            {{ $t('CANNED_MGMT.EDIT.FORM.CONTENT.LABEL') }}
          </label>
          <div class="editor-wrap">
            <woot-message-editor
              v-model="content"
              class="message-editor"
              :class="{ editor_warning: $v.content.$error }"
              :enable-variables="true"
              :enable-canned-responses="false"
              :placeholder="$t('CANNED_MGMT.EDIT.FORM.CONTENT.PLACEHOLDER')"
            />
          </div>
          <multiselect
        v-model="currentSelectedFilter"
        :placeholder="$t('LABEL_REPORTS.FILTER_DROPDOWN_LABEL')"
        label="title"
        track-by="id"
        :options="labelList"
        :option-height="24"
        :show-labels="false"
      ><template slot="singleLabel" slot-scope="props">
        <div class="reports-option__wrap">
          <div
            :style="{ backgroundColor: props.option.color }"
            class="reports-option__rounded--item"
          />
          <span class="reports-option__desc">
            <span class="reports-option__title">
              {{ props.option.title }}
            </span>
          </span>
        </div>
      </template>
      <template slot="option" slot-scope="props">
        <div class="reports-option__wrap">
          <div
            :style="{ backgroundColor: props.option.color }"
            class="
                reports-option__rounded--item
                reports-option__item
                reports-option__label--swatch
              "
          />
          <span class="reports-option__desc">
            <span class="reports-option__title">
              {{ props.option.title }}
            </span>
          </span>
        </div>
      </template>
    </multiselect>
    <file-upload class="change-media"
        :accept="allowedFileTypes"
        @input-file="onDirectFileUpload($event)">
        <woot-button class="change-button">{{ $t('CANNED_MGMT.ADD.FORM.ADD_FILE') }}</woot-button>
      </file-upload>
        <div v-if="media" class="preview">
          <div @click="openModalMedia" class="btn-preview">
            <img v-if="verifyMedia == 'image'" :src="media">
            <vue-pdf-embed
              v-if="verifyMedia == 'pdf'"
              :source="media"
              :width="85"
              :scale="1"
              :page="1"
            />
          </div>
          <audio v-if="verifyMedia === 'audio'" :src="media" controls />

          <video v-if="verifyMedia === 'video'" :src="media" controls
            playsInline
            class="modal-video skip-context-menu">
          </video>
          <div v-if="verifyMedia == 'unknown'"   class="document">📄</div>
        </div>

        </div>
        <div class="modal-footer">
          <div class="medium-12 columns">
            <woot-submit-button
              :disabled="
                $v.content.$invalid ||
                  $v.shortCode.$invalid ||
                  editCanned.showLoading
              "
              :button-text="$t('CANNED_MGMT.EDIT.FORM.SUBMIT')"
              :loading="editCanned.showLoading"
            />
            <button class="button clear" @click.prevent="onClose">
              {{ $t('CANNED_MGMT.EDIT.CANCEL_BUTTON_TEXT') }}
            </button>
          </div>
        </div>
      </form>
      <modal-media
        v-if="modalMedia"
        :show="modalMedia"
        :fileType="verifyMedia"
        :src="media"
        @cancel="closeModalMedia"
      />
    </div>
  </modal>
</template>

<script>
/* eslint no-console: 0 */
import { DirectUpload } from 'activestorage';
import WootMessageEditor from 'dashboard/components/widgets/WootWriter/Editor';
import {
  ALLOWED_FILE_TYPES,
  MAXIMUM_FILE_UPLOAD_SIZE,
  MAXIMUM_FILE_UPLOAD_SIZE_TWILIO_SMS_CHANNEL, MESSAGE_VARIABLES
} from 'shared/constants/messages';
import { checkFileSizeLimit } from 'shared/helpers/FileHelper';
import alertMixin from 'shared/mixins/alertMixin';
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed';
import FileUpload from 'vue-upload-component';
import { minLength, required } from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';
import WootSubmitButton from '../../../../components/buttons/FormSubmitButton';
import Modal from '../../../../components/Modal';
import ModalMedia from './ModalMedia.vue';
import SuggestionButtons from './SuggestionButtons';


export default {
  components: {
    WootSubmitButton,
    Modal,
    SuggestionButtons,
    WootMessageEditor,
    FileUpload,
    VuePdfEmbed,
    ModalMedia
  },
  mixins: [alertMixin],
  props: {
    id: { type: Number, default: null },
    edlabelId: {type: Number,default: null},
    edcontent: { type: String, default: '' },
    edshortCode: { type: String, default: '' },
    edmedia: {type:String, default:''},
    onClose: { type: Function, default: () => {} },
  },
  data() {
    return {
      suggestions: [],
      currentSelectedFilter: null,
      editCanned: {
        showAlert: false,
        showLoading: false,
      },
      shortCode: this.edshortCode,
      content: this.edcontent,
      media:this.edmedia,
      modalMedia: false,
      show: true,
    };
  },
  validations: {
    shortCode: {
      required,
      minLength: minLength(2),
    },
    content: {
      required,
    },
  },
  computed: {
    ...mapGetters({
      currentUser: 'getCurrentUser',
      labelList: 'labels/getLabels',
    }),
    allowedFileTypes() {
      return ALLOWED_FILE_TYPES;
    },

    verifyMedia(){
      const attachmentUrl = this.media?.toLowerCase();
      const imageExtensions = ['.jpg', '.jpeg', '.png', '.gif', '.bmp'];
      const videoExtensions = ['.mp4', '.avi', '.mov', '.mkv', '.flv'];
      const audioExtensions = ['.mp3', '.wav', '.aac', '.flac', '.ogg'];
      const checkFileType = (extensions) => extensions.some(ext => attachmentUrl?.endsWith(ext));
      if (checkFileType(imageExtensions)) return 'image';
      if (checkFileType(videoExtensions)) return 'video';
      if (checkFileType(audioExtensions)) return 'audio';
      if (checkFileType(['pdf'])) return 'pdf';
      return 'unknown';
    },

    pageTitle() {
      return `${this.$t('CANNED_MGMT.EDIT.TITLE')} - ${this.edshortCode}`;
    },
  },
  mounted(){
    this.suggestions = MESSAGE_VARIABLES.filter((obj) => obj?.label?.includes("Contact") || obj?.label == "Greeting");
    this.currentTag()
  },
  methods: {
    openModalMedia(){
      this.modalMedia = true;
    },
    closeModalMedia(){
      this.modalMedia = false;
    },

    setPageName({ name }) {
      this.$v.content.$touch();
      this.content = name;
    },
    currentTag(){
      this.currentSelectedFilter = this.labelList.find(item => item?.id == this.edlabelId)
    },
    customVariable(value){
     this.content = this.content += ` {{${value}}}&nbsp;`;
    },
    onDirectFileUpload(file) {
      const MAXIMUM_SUPPORTED_FILE_UPLOAD_SIZE = this.isATwilioSMSChannel
        ? MAXIMUM_FILE_UPLOAD_SIZE_TWILIO_SMS_CHANNEL
        : MAXIMUM_FILE_UPLOAD_SIZE;

      if (!file) {
        return;
      }
      if (checkFileSizeLimit(file, MAXIMUM_SUPPORTED_FILE_UPLOAD_SIZE)) {
        this.editCanned.showLoading = true;
        const upload = new DirectUpload(
          file.file,
          `/api/v1/accounts/${this.accountId}/direct_uploads`,
          {
            directUploadWillCreateBlobWithXHR: xhr => {
              xhr.setRequestHeader(
                'api_access_token',
                this.currentUser.access_token
              );
            },
          }
        );
        upload.create((error, blob) => {
          if (error) {
            this.showAlert(error);
          } else {
            const fileUrl = `${window.location.origin}/rails/active_storage/blobs/${blob.signed_id}/${blob.filename}`;
            this.media = fileUrl;
          }
          this.editCanned.showLoading = false;
        });
      } else {
        this.showAlert(
          this.$t('CONVERSATION.FILE_SIZE_LIMIT', {
            MAXIMUM_SUPPORTED_FILE_UPLOAD_SIZE,
          })
        );
      }
    },
    resetForm() {
      this.shortCode = '';
      this.content = '';
      this.$v.shortCode.$reset();
      this.$v.content.$reset();
    },
    editCannedResponse() {
      // Show loading on button
      this.editCanned.showLoading = true;
      // Make API Calls
      this.$store
        .dispatch('updateCannedResponse', {
          id: this.id,
          short_code: this.shortCode,
          content: this.content,
          tag_id: this.currentSelectedFilter?.id ? this.currentSelectedFilter?.id : null,
          media_url: this.media
        })
        .then(() => {
          // Reset Form, Show success message
          this.editCanned.showLoading = false;
          this.showAlert(this.$t('CANNED_MGMT.EDIT.API.SUCCESS_MESSAGE'));
          this.resetForm();
          setTimeout(() => {
            this.onClose();
          }, 10);
        })
        .catch(error => {
          this.editCanned.showLoading = false;
          const errorMessage =
            error?.message || this.$t('CANNED_MGMT.EDIT.API.ERROR_MESSAGE');
          this.showAlert(errorMessage);
        });
    },
  },
};
</script>
<style scoped lang="scss">

.preview{
  .btn-preview {
    cursor: pointer;
  }
    .document {
      font-size: 5rem;
    }
    img{
      height: 50px;
      border-radius: 5px;
    }

    video {
      max-height: 200px;
    }

  }
::v-deep {
  .ProseMirror-menubar {
    display: none;
  }
  .content-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .image-preview {
    width: 100px;
    border-radius: 5px;
  }

  .ProseMirror-woot-style {
    min-height: 20rem;

    p {
      font-size: var(--font-size-default);
    }
  }

  .message-editor {
    border: 1px solid var(--s-200);
  }
}
</style>
