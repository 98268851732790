<template>
  <woot-modal :show.sync="show" :on-close="onClose">
    <div class="column content-box">
      <woot-modal-header :header-title="$t('ATTRIBUTES_MGMT.EDIT.TITLE')" />

      <div>
        <div class="main-form">

          <div>
            <span class="span-label">
              <form @submit.prevent="addTable">
                <woot-input class="input-text" v-model.trim="selectedTable.name" :label="$t('CUSTOM_ATTRIBUTES.FORM.DEFAULT_VALUES.NAME')" type="text"
                  :placeholder="$t('CUSTOM_ATTRIBUTES.FORM.DEFAULT_VALUES.NAME')" />
                <div class="field">
                  <woot-input class="input-text" v-model.trim="selectedTable.company.name"
                     :type="selectedTable.company.visibility ? 'text': 'password'"
                    :placeholder="$t('CUSTOM_ATTRIBUTES.FORM.DEFAULT_VALUES.FIRST_FIELD')" :label="$t('CUSTOM_ATTRIBUTES.FORM.DEFAULT_VALUES.FIRST_FIELD')" />
                  <div class="block">
                    <label>{{ $t('CUSTOM_ATTRIBUTES.FORM.VISIBILITY') }}</label>
                    <input class="checkbox" v-model.trim="selectedTable.company.visibility" type="checkbox" />
                  </div>
                </div>
                <div class="field">
                  <woot-input class="input-text" v-model.trim="selectedTable.client_code.name"
                     :type="selectedTable.client_code.visibility ? 'text': 'password'"
                    :placeholder="$t('CUSTOM_ATTRIBUTES.FORM.DEFAULT_VALUES.SECOND_FIELD')" :label="$t('CUSTOM_ATTRIBUTES.FORM.DEFAULT_VALUES.SECOND_FIELD')" />
                  <div class="block">
                    <label>{{ $t('CUSTOM_ATTRIBUTES.FORM.VISIBILITY') }}</label>
                    <input class="checkbox" v-model.trim="selectedTable.client_code.visibility" type="checkbox" />
                  </div>
                </div>
                <div class="field">
                  <woot-input class="input-text" v-model.trim="selectedTable.status.name"
                    :type="selectedTable.status.visibility ? 'text': 'password'"
                    :placeholder="$t('CUSTOM_ATTRIBUTES.FORM.DEFAULT_VALUES.THIRD_FIELD')" :label="$t('CUSTOM_ATTRIBUTES.FORM.DEFAULT_VALUES.THIRD_FIELD')" />
                  <div class="block">
                    <label>{{ $t('CUSTOM_ATTRIBUTES.FORM.VISIBILITY') }}</label>
                    <input class="checkbox" v-model.trim="selectedTable.status.visibility" type="checkbox" />
                  </div>
                </div>
                <div class="field">
                  <woot-input class="input-text" v-model.trim="selectedTable.monthly_fee.name"
                    :type="selectedTable.monthly_fee.visibility ? 'text': 'password'"
                    :placeholder="$t('CUSTOM_ATTRIBUTES.FORM.DEFAULT_VALUES.FOURTH_FIELD')" :label="$t('CUSTOM_ATTRIBUTES.FORM.DEFAULT_VALUES.FOURTH_FIELD')" />
                  <div class="block">
                    <label>{{ $t('CUSTOM_ATTRIBUTES.FORM.VISIBILITY') }}</label>
                    <input class="checkbox" v-model.trim="selectedTable.monthly_fee.visibility" type="checkbox" />
                  </div>
                </div>
                <div class="field">
                  <woot-input class="input-text" v-model.trim="selectedTable.custom_field_first.name"
                    :label="$t('CUSTOM_ATTRIBUTES.FORM.DEFAULT_VALUES.CUSTOM_ONE')" :type="selectedTable.custom_field_first.visibility ? 'text': 'password'"
                    :placeholder="$t('CUSTOM_ATTRIBUTES.FORM.DEFAULT_VALUES.CUSTOM_ONE')" />
                  <div class="block">
                    <label>{{ $t('CUSTOM_ATTRIBUTES.FORM.VISIBILITY') }}</label>
                    <input class="checkbox" v-model.trim="selectedTable.custom_field_first.visibility"
                      type="checkbox" />
                  </div>
                </div>
                <div class="field">
                  <woot-input class="input-text" v-model.trim="selectedTable.custom_field_second.name"
                    :label="$t('CUSTOM_ATTRIBUTES.FORM.DEFAULT_VALUES.CUSTOM_TWO')" :type="selectedTable.custom_field_second.visibility ? 'text': 'password'"
                    :placeholder="$t('CUSTOM_ATTRIBUTES.FORM.DEFAULT_VALUES.CUSTOM_TWO')" />
                  <div class="block">
                    <label>{{ $t('CUSTOM_ATTRIBUTES.FORM.VISIBILITY') }}</label>
                    <input class="checkbox" v-model.trim="selectedTable.custom_field_second.visibility"
                      type="checkbox" />
                  </div>
                </div>
                <div class="modal-footer">
                  <woot-button>
                    {{ $t('CUSTOM_ATTRIBUTES.FORM.CREATE') }}
                  </woot-button>
                  <woot-button variant="clear" @click.prevent="onClose">
                    {{ $t('CUSTOM_ATTRIBUTES.FORM.CANCEL') }}
                  </woot-button>
                </div>
              </form>
            </span>

          </div>

        </div>
      </div>
    </div>
  </woot-modal>
</template>

<script>
import alertMixin from 'shared/mixins/alertMixin';
import { minLength, required } from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';

export default {
  mixins: [alertMixin],
  props: {
    selectedTable: {
      type: Object,
      default: {},
    }
  },

  data() {
    return {
      values: [],
      options: [],
      show: true,
      isTouched: false,
    };
  },

  mounted() {
  },

  computed: {
    ...mapGetters({
      uiFlags: 'getUIFlags',
      indexTab: 'attributes/getIndexTabAttributes',
    }),
    groupAttributes() {
      const customAttribute = this.$store.getters['attributes/getAttributesByModel'](
        'group_attribute'
      );
      return customAttribute
    },
  },


  validations: {
    selectedTable: {
      name: {
        required,
        minLength: minLength(1),
      },
      company: {}

    }
  },

  methods: {
    onClose(){
      this.$emit('on-close')
    },
    onTouch() {
      this.isTouched = true;
    },
    async addTable() {
      try {
        await this.$store.dispatch('attributes/update', {
          attributeType: 'group_table',
          id: this.selectedTable.id,
          name: this.selectedTable.name,
          company: this.selectedTable.company || {},
          client_code: this.selectedTable.client_code || {},
          status: this.selectedTable.status || {},
          monthly_fee: this.selectedTable.monthly_fee || {},
          custom_field_first: this.selectedTable.custom_field_first || {},
          custom_field_second: this.selectedTable.custom_field_second || {},
        });
        this.onClose();
      } catch (error) {
        console.error(`Erro ao processar`, error);
        throw error;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.field {
  display: flex;
  gap: 10px;
}

.field .input-text {
  flex-grow: 1;
}

.checkbox-container {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.checkbox {
  margin-top: 15px;
  margin-left: 5px;
  transform: scale(1.5);
  display: flex;
  align-items: center;
  justify-content: center;
}


::v-deep {
  .multiselect {
    margin-bottom: 0;
  }

  .multiselect__content-wrapper {
    display: none;
  }

  .multiselect--active .multiselect__tags {
    border-radius: var(--border-radius-normal);
  }

  .tabs {
    padding: 0;
  }

}


.main-form {
  margin-top: 1rem;
}

.span-label {
  display: block;
  margin: 0;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1.8;
  color: #3c4858;
}
</style>
