import transcriptionAxios from './transcriptionInstance';
const {
    TRANSCRIPTION_SECRET: transcriptionSecret,
    TRANSCRIPTION_TOKEN: transcriptionToken
} = window.globalConfig || {};

class TranscriptionApi {
    constructor() {
      this.endpoint = '/';
    }
  
    getTranscription({ fileUrl }) {
        const paylod = {
            fileUrl,
            model: "whisper-1",
            secret: transcriptionSecret,
            token: transcriptionToken
        }
        
      return transcriptionAxios.post(this.endpoint, paylod)
    }
  
  }
  
  export default new TranscriptionApi();
  