/* eslint no-console: 0 */
import * as types from '../mutation-types';
import Report from '../../api/reports';
import { downloadCsvFile, generateFileName } from '../../helper/downloadHelper';
import AnalyticsHelper from '../../helper/AnalyticsHelper';
import { REPORTS_EVENTS } from '../../helper/AnalyticsHelper/events';
import {
  reconcileHeatmapData,
  clampDataBetweenTimeline,
} from 'shared/helpers/ReportsDataHelper';

const state = {
  fetchingStatus: false,
  reportData: [],
  accountReport: {
    isFetching: true,
    data: [],
  },
  labelTable: {
    isFetching: true,
    data: [],
  },
  accountSummary: {
    isFetching: true,
    avg_first_response_time: 0,
    avg_resolution_time: 0,
    conversations_count: 0,
    incoming_messages_count: 0,
    bot_dashboard_conversation_count: 0,
    bot_dashboard_resolution_count: 0,
    label_table: [],
    get_labels_count: 0,
    outgoing_messages_count: 0,
    resolutions_count: 0,
    previous: {},
  },
  accountInboxSummary: {
    avg_first_response_time: 0,
    avg_resolution_time: 0,
    conversations_count: 0,
    incoming_messages_count: 0,
    outgoing_messages_count: 0,
    resolutions_count: 0,
    previous: {},
  },
  overview: {
    uiFlags: {
      isFetchingAccountConversationMetric: false,
      isFetchingAccountConversationsHeatmap: false,
      isFetchingAgentConversationMetric: false,
    },
    accountConversationMetric: {},
    accountConversationHeatmap: [],
    agentConversationMetric: [],
  },
};

const getters = {
  getAccountReports(_state) {
    return _state.accountReport;
  },
  getLabelTable(_state) {
    return _state.labelTable;
  },
  getAccountSummary(_state) {
    return _state.accountSummary;
  },
  getAccountInboxSummary(_state) {
    return _state.accountInboxSummary;
  },
  getAccountConversationMetric(_state) {
    return _state.overview.accountConversationMetric;
  },
  getAccountConversationHeatmapData(_state) {
    return _state.overview.accountConversationHeatmap;
  },
  getAgentConversationMetric(_state) {
    return _state.overview.agentConversationMetric;
  },
  getOverviewUIFlags($state) {
    return $state.overview.uiFlags;
  },
};

const splitDateRange = (from, to, month = 3) => {
  const ranges = [];
  let fromDate = new Date(from * 1000);
  const toDate = new Date(to * 1000);

  while (fromDate <= toDate) {
    let endDate = new Date(fromDate);
    endDate.setMonth(fromDate.getMonth() + month);

    if (endDate > toDate) {
      endDate = new Date(toDate);
    }

    ranges.push({
      from: Math.floor(fromDate.getTime() / 1000),
      to: Math.floor(endDate.getTime() / 1000),
    });

    fromDate = new Date(endDate.getTime() + 1);
  }

  return ranges;
};

export const actions = {
  fetchAccountReport({ commit, dispatch }, reportObj) {
    commit(types.default.TOGGLE_ACCOUNT_REPORT_LOADING, true);

    const dateRanges = splitDateRange(reportObj.from, reportObj.to, 1);

    const promises = dateRanges.map(range => {
      const { from, to, ...restOfReportObj } = reportObj;

      return Report.getReports({
        from: range.from,
        to: range.to,
        ...restOfReportObj,
      }).then(accountReport => {
        return clampDataBetweenTimeline(
          accountReport.data,
          range.from,
          range.to
        );
      });
    });

    Promise.all(promises)
      .then(reports => {
        const combinedReports = reports.flat();
        commit(types.default.SET_ACCOUNT_REPORTS, combinedReports);
        commit(types.default.TOGGLE_ACCOUNT_REPORT_LOADING, false);
      })
      .catch(error => {
        console.error('Error fetching reports:', error);
        commit(types.default.TOGGLE_ACCOUNT_REPORT_LOADING, false);
      });
  },

  setAccountReport({ commit }, data) {
    commit(types.default.TOGGLE_ACCOUNT_REPORT_LOADING, true);
    let { report } = data;
    report = clampDataBetweenTimeline(
      report,
      data?.report?.report_params?.since,
      data?.report?.report_params?.until
    );
    commit(types.default.SET_ACCOUNT_REPORTS, report);
    commit(types.default.TOGGLE_ACCOUNT_REPORT_LOADING, false);
  },
  fetchLabelTable({ commit }, reportObj) {
    commit(types.default.TOGGLE_LABEL_TABLE_LOADING, true);
    Report.getLabelTable(reportObj).then(labelTable => {
      let { data } = labelTable;
      commit(types.default.SET_LABEL_TABLE, data);
      commit(types.default.TOGGLE_LABEL_TABLE_LOADING, false);
    });
  },
  setLabelTable({ commit }, data) {
    commit(types.default.TOGGLE_LABEL_TABLE_LOADING, true);
    let { report } = data;
    commit(types.default.SET_LABEL_TABLE, report);
    commit(types.default.TOGGLE_LABEL_TABLE_LOADING, false);
  },
  fetchAccountConversationHeatmap({ commit }, reportObj) {
    commit(types.default.TOGGLE_HEATMAP_LOADING, true);
    Report.getReports({ ...reportObj, groupBy: 'hour' }).then(heatmapData => {
      let { data } = heatmapData;
      data = clampDataBetweenTimeline(data, reportObj.from, reportObj.to);

      data = reconcileHeatmapData(
        data,
        state.overview.accountConversationHeatmap
      );

      commit(types.default.SET_HEATMAP_DATA, data);
      commit(types.default.TOGGLE_HEATMAP_LOADING, false);
    });
  },
  fetchAccountSummary({ commit, dispatch }, reportObj) {
    commit(types.default.TOGGLE_ACCOUNT_SUMMARY_LOADING, true);

    const dateRanges = splitDateRange(reportObj.from, reportObj.to, 2);
    const promises = dateRanges.map((range, index) => {
      return Report.getSummary(
        range.from,
        range.to,
        reportObj.type,
        reportObj.id,
        reportObj.groupBy,
        reportObj.businessHours,
        reportObj.page,
        reportObj.per_page
      ).then(accountSummary => {
        if (accountSummary.data.token) {
          return dispatch('checkSummaryStatusForMultipleRequests', {
            token: accountSummary.data.token,
            route: reportObj.route,
          });
        }
        return accountSummary.data;
      });
    });
    Promise.all(promises)
      .then(summaries => {
        dispatch('combineAndSetSummaries', {
          allSummaries: summaries,
          route: reportObj.route,
        });
      })
      .catch(() => {
        commit(types.default.TOGGLE_ACCOUNT_REPORT_LOADING, false);
      });
  },

  checkSummaryStatusForMultipleRequests(
    { commit, dispatch },
    { token, route }
  ) {
    return new Promise((resolve, reject) => {
      let n = 0;

      const intervalId = setInterval(() => {
        n++;

        if (n > 50) {
          clearInterval(intervalId);
          reject('Timeout on checking status');
          return;
        }

        Report.checkCacheStatus(token).then(response => {
          if (!response.data.message) {
            clearInterval(intervalId);
            resolve(response.data);
          }
        });
      }, 5000);
    });
  },

  combineAndSetSummaries({ commit, dispatch }, { allSummaries, route }) {
    const result = allSummaries.reduce(
      (acc, item) => {
        acc.conversations_count += item.conversations_count;
        acc.incoming_messages_count += item.incoming_messages_count;
        acc.outgoing_messages_count += item.outgoing_messages_count;
        acc.incoming_conversations_count += item.incoming_conversations_count;
        acc.outgoing_conversations_count += item.outgoing_conversations_count;
        acc.resolutions_count += item.resolutions_count;
        acc.bot_dashboard_resolution_count +=
          item.bot_dashboard_resolution_count;
        acc.bot_dashboard_conversation_count +=
          item.bot_dashboard_conversation_count;
        acc.bot_conversation_count += item.bot_conversation_count;
        acc.bot_outgoing_messages_count += item.bot_outgoing_messages_count;
        acc.bot_all_conversation_solved_count +=
          item.bot_all_conversation_solved_count;
        acc.bot_resolution_count += item.bot_resolution_count;
        acc.bot_resolution_rate += item.bot_resolution_rate;
        acc.bot_all_conversation_count += item.bot_all_conversation_count;
        acc.bot_avg_handling_time += item.bot_avg_handling_time;
        acc.bot_incoming_messages_count += item.bot_incoming_messages_count;
        acc.avg_first_response_time += item.avg_first_response_time;
        acc.avg_handling_time += item.avg_handling_time;
        acc.avg_resolution_time += item.avg_resolution_time;
        acc.previous = acc.previous || {
          conversations_count: 0,
          incoming_messages_count: 0,
          outgoing_messages_count: 0,
          incoming_conversations_count: 0,
          outgoing_conversations_count: 0,
          avg_first_response_time: 0,
          avg_handling_time: 0,
          avg_resolution_time: 0,
          resolutions_count: 0,
          bot_dashboard_resolution_count: 0,
          bot_dashboard_conversation_count: 0,
        };
        acc.previous.conversations_count += item.previous.conversations_count;
        acc.previous.incoming_messages_count +=
          item.previous.incoming_messages_count;
        acc.previous.outgoing_messages_count +=
          item.previous.outgoing_messages_count;
        acc.previous.incoming_conversations_count +=
          item.previous.incoming_conversations_count;
        acc.previous.outgoing_conversations_count +=
          item.previous.outgoing_conversations_count;
        acc.previous.resolutions_count += item.previous.resolutions_count;
        acc.previous.bot_dashboard_resolution_count +=
          item.previous.bot_dashboard_resolution_count;
        acc.previous.bot_dashboard_conversation_count +=
          item.previous.bot_dashboard_conversation_count;
        acc.previous.bot_conversation_count +=
          item.previous.bot_conversation_count;
        acc.previous.avg_bot_time += item.previous.avg_bot_time;
        acc.previous.bot_outgoing_messages_count +=
          item.previous.bot_outgoing_messages_count;
        acc.previous.bot_all_conversation_solved_count +=
          item.previous.bot_all_conversation_solved_count;
        acc.previous.bot_resolution_count += item.previous.bot_resolution_count;
        acc.previous.bot_resolution_rate += item.previous.bot_resolution_rate;
        acc.previous.bot_all_conversation_count +=
          item.previous.bot_all_conversation_count;
        acc.previous.bot_avg_handling_time_summary +=
          item.previous.bot_avg_handling_time_summary;
        acc.previous.bot_incoming_messages_count +=
          item.previous.bot_incoming_messages_count;
        acc.previous.avg_first_response_time +=
          item.previous.avg_first_response_time;
        acc.previous.avg_handling_time += item.previous.avg_handling_time;
        acc.previous.avg_resolution_time += item.previous.avg_resolution_time;

        return acc;
      },
      {
        conversations_count: 0,
        incoming_messages_count: 0,
        outgoing_messages_count: 0,
        avg_first_response_time: 0,
        incoming_conversations_count: 0,
        outgoing_conversations_count: 0,
        avg_handling_time: 0,
        avg_resolution_time: 0,
        bot_conversation_count: 0,
        bot_outgoing_messages_count: 0,
        bot_all_conversation_solved_count: 0,
        bot_resolution_count: 0,
        avg_bot_time: 0,
        bot_resolution_rate: 0,
        bot_all_conversation_count: 0,
        bot_avg_handling_time: 0,
        bot_incoming_messages_count: 0,
        resolutions_count: 0,
        bot_dashboard_resolution_count: 0,
        bot_dashboard_conversation_count: 0,
        previous: {
          conversations_count: 0,
          incoming_messages_count: 0,
          outgoing_messages_count: 0,
          incoming_conversations_count: 0,
          outgoing_conversations_count: 0,
          avg_first_response_time: 0,
          bot_conversation_count: 0,
          bot_outgoing_messages_count: 0,
          bot_all_conversation_solved_count: 0,
          bot_resolution_count: 0,
          bot_resolution_rate: 0,
          bot_all_conversation_count: 0,
          avg_bot_time: 0,
          bot_avg_handling_time: 0,
          bot_incoming_messages_count: 0,
          avg_handling_time: 0,
          avg_resolution_time: 0,
          resolutions_count: 0,
          bot_dashboard_resolution_count: 0,
          bot_dashboard_conversation_count: 0,
        },
      }
    );
    const count = allSummaries.length;
    result.avg_first_response_time /= count;
    result.avg_handling_time /= count;
    result.avg_resolution_time /= count;
    result.avg_bot_time /= count;
    result.bot_avg_handling_time /= count;

    const previousCount = allSummaries.reduce((acc, item) => acc + 1, 0); // Contagem para previous
    result.previous.avg_first_response_time /= previousCount;
    result.previous.avg_handling_time /= previousCount;
    result.previous.avg_resolution_time /= previousCount;
    result.previous.avg_bot_time /= count;
    result.previous.bot_avg_handling_time /= count;

    dispatch('setAccountSummary', { data: result, route });
  },

  setAccountSummary({ commit }, { data, route }) {
    if (typeof data === 'object' && data !== null) {
      if (route) {
        commit(types.default.SET_ACCOUNT_INBOX_SUMMARY, data);
      } else {
        commit(types.default.SET_ACCOUNT_SUMMARY, data);
      }
    } else {
      console.error('Dados inválidos para setAccountSummary:', data);
    }
    commit(types.default.TOGGLE_ACCOUNT_SUMMARY_LOADING, false);
  },

  fetchAccountConversationMetric({ commit }, reportObj) {
    commit(types.default.TOGGLE_ACCOUNT_CONVERSATION_METRIC_LOADING, true);
    Report.getConversationMetric(reportObj.type)
      .then(accountConversationMetric => {
        commit(
          types.default.SET_ACCOUNT_CONVERSATION_METRIC,
          accountConversationMetric.data
        );
        commit(types.default.TOGGLE_ACCOUNT_CONVERSATION_METRIC_LOADING, false);
      })
      .catch(() => {
        commit(types.default.TOGGLE_ACCOUNT_CONVERSATION_METRIC_LOADING, false);
      });
  },
  fetchAgentConversationMetric({ commit }, reportObj) {
    commit(types.default.TOGGLE_AGENT_CONVERSATION_METRIC_LOADING, true);
    Report.getConversationMetric(reportObj.type, reportObj.page)
      .then(agentConversationMetric => {
        commit(
          types.default.SET_AGENT_CONVERSATION_METRIC,
          agentConversationMetric.data
        );
        commit(types.default.TOGGLE_AGENT_CONVERSATION_METRIC_LOADING, false);
      })
      .catch(() => {
        commit(types.default.TOGGLE_AGENT_CONVERSATION_METRIC_LOADING, false);
      });
  },
  downloadAgentReports(_, reportObj) {
    console.log(reportObj);
    return Report.getAgentReports(reportObj)
      .then(response => {
        downloadCsvFile(reportObj.fileName, response.data);
        AnalyticsHelper.track(REPORTS_EVENTS.DOWNLOAD_REPORT, {
          reportType: 'agent',
          businessHours: reportObj?.businessHours,
        });
      })
      .catch(error => {
        console.error(error);
      });
  },
  downloadLabelReports(_, reportObj) {
    return Report.getLabelReports(reportObj)
      .then(response => {
        downloadCsvFile(reportObj.fileName, response.data);
        AnalyticsHelper.track(REPORTS_EVENTS.DOWNLOAD_REPORT, {
          reportType: 'label',
          businessHours: reportObj?.businessHours,
        });
      })
      .catch(error => {
        console.error(error);
      });
  },
  downloadLabelTable(_, reportObj) {
    return Report.getLabelTableReports(reportObj)
      .then(response => {
        downloadCsvFile(reportObj.fileName, response.data);
        AnalyticsHelper.track(REPORTS_EVENTS.DOWNLOAD_REPORT, {
          reportType: 'label',
          businessHours: reportObj?.businessHours,
        });
      })
      .catch(error => {
        console.error(error);
      });
  },
  downloadInboxReports(_, reportObj) {
    return Report.getInboxReports(reportObj)
      .then(response => {
        console.log;
        downloadCsvFile(reportObj.fileName, response.data);
        AnalyticsHelper.track(REPORTS_EVENTS.DOWNLOAD_REPORT, {
          reportType: 'inbox',
          businessHours: reportObj?.businessHours,
        });
      })
      .catch(error => {
        console.error(error);
      });
  },
  downloadTeamReports(_, reportObj) {
    return Report.getTeamReports(reportObj)
      .then(response => {
        downloadCsvFile(reportObj.fileName, response.data);
        AnalyticsHelper.track(REPORTS_EVENTS.DOWNLOAD_REPORT, {
          reportType: 'team',
          businessHours: reportObj?.businessHours,
        });
      })
      .catch(error => {
        console.error(error);
      });
  },
  downloadAccountConversationHeatmap(_, reportObj) {
    Report.getConversationTrafficCSV()
      .then(response => {
        downloadCsvFile(
          generateFileName({
            type: 'Conversation traffic',
            to: reportObj.to,
          }),
          response.data
        );

        AnalyticsHelper.track(REPORTS_EVENTS.DOWNLOAD_REPORT, {
          reportType: 'conversation_heatmap',
          businessHours: false,
        });
      })
      .catch(error => {
        console.error(error);
      });
  },
};

const mutations = {
  [types.default.SET_ACCOUNT_REPORTS](_state, accountReport) {
    _state.accountReport.data = accountReport;
  },
  [types.default.SET_LABEL_TABLE](_state, labelTable) {
    _state.labelTable.data = labelTable;
  },
  [types.default.TOGGLE_LABEL_TABLE_LOADING](_state, labelTable) {
    _state.labelTable.isFetching = labelTable;
  },
  [types.default.SET_HEATMAP_DATA](_state, heatmapData) {
    _state.overview.accountConversationHeatmap = heatmapData;
  },
  [types.default.TOGGLE_ACCOUNT_REPORT_LOADING](_state, flag) {
    _state.accountReport.isFetching = flag;
  },
  [types.default.TOGGLE_ACCOUNT_SUMMARY_LOADING](_state, flag) {
    _state.accountSummary.isFetching = flag;
  },
  [types.default.TOGGLE_HEATMAP_LOADING](_state, flag) {
    _state.overview.uiFlags.isFetchingAccountConversationsHeatmap = flag;
  },
  [types.default.SET_ACCOUNT_SUMMARY](_state, summaryData) {
    _state.accountSummary = summaryData;
  },
  [types.default.SET_ACCOUNT_INBOX_SUMMARY](_state, summaryData) {
    _state.accountInboxSummary = summaryData;
  },
  [types.default.SET_ACCOUNT_CONVERSATION_METRIC](_state, metricData) {
    _state.overview.accountConversationMetric = metricData;
  },
  [types.default.TOGGLE_ACCOUNT_CONVERSATION_METRIC_LOADING](_state, flag) {
    _state.overview.uiFlags.isFetchingAccountConversationMetric = flag;
  },
  [types.default.SET_AGENT_CONVERSATION_METRIC](_state, metricData) {
    _state.overview.agentConversationMetric = metricData;
  },
  [types.default.TOGGLE_AGENT_CONVERSATION_METRIC_LOADING](_state, flag) {
    _state.overview.uiFlags.isFetchingAgentConversationMetric = flag;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
